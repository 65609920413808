import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './naturefinder.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import Explore from './Explore.gif'
import Map from './Map.gif'
import Rewards from './Rewards.gif'
import divider from './divider.png'
import discover_1 from './discover_1.jpg'
import discover_2 from './discover_2.jpg'
import discover_3 from './discover_3.jpg'
import ideation_1 from './ideation_1.jpg'
import ideation_2 from './ideation_2.png'
import ideation_3 from './ideation_3.png'
import ideation_4 from './ideation_4.png'
import ideation_5 from './ideation_5.jpg'
import design_1 from './design_1.png'
import design_2 from './design_2.png'
import design_3 from './design_3.jpg'
import design_4 from './design_4.jpeg'
import protoiterate_1 from './protoiterate_1.jpg'
import protoiterate_2 from './protoiterate_2.png'
import protoiterate_3 from './protoiterate_3.jpg'
import protoiterate_4 from './protoiterate_4.jpg'
import protoiterate_5 from './protoiterate_5.png'
import protoiterate_6 from './protoiterate_6.png'
import hifiproto_1 from './hi-fiproto_1.jpg'
import reflection_1 from './reflection_1.jpg'

/*98B5EF*/
/*6582BC*/


class NatureFinder extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="nf-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Discover" to="discover" spy={true} smooth={true} duration={500}>Discover</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Ideation" to="ideation" spy={true} smooth={true} duration={500}>Ideation</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Design" to="design" spy={true} smooth={true} duration={500}>Design</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Protoype + Iteration" to="protoiteration" spy={true} smooth={true} duration={500}>Prototype + Iteration</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Hi-fi Product" to="hi-fi" spy={true} smooth={true} duration={500}>Hi-fi Product</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} className="Reflection" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Blue Heart" style={{ fontSize: "inherit" }}>💙</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title">
                        Nature Finder: Hikers' Trail Finder<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        UX Research + Design
                        </div> 

                        <Element className="overview">
                                <Fade bottom>
                                    
                                    <h1>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> In the Seattle area, the vast and wondrous nature has brought forth avid hikers that tread miles of its earthy ground. 
                                        Nature Finder is a three-month class project that explored hikers’ needs and wants. The four of us collaborated to create a mobile 
                                        application focused on improving hikers' trail searching experience and safety.
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#98B5EF"}}>Role</h2>
                                        <p>UX Designer | UX Researcher</p>
                                        <h2 style={{ color: "#98B5EF" }}>Duration</h2>
                                        <p>Mar 2019 to Jun 2019 (3 months)</p>
                                        <h2 style={{ color: "#98B5EF" }}>Team</h2>
                                        <p>Nancy Ou | Taylor Toman | Derick Yap</p>
                                        <h2 style={{ color: "#98B5EF" }}>Tools</h2>
                                        <p>Adobe Suite | Google Suite | Figma | Lucid | Paper</p>
                                    </div>
                                    </div>
                                    <img src={divider} className="divider" width="400rem" alt="divider"></img>
                                
                                    <div className="extra-large-text-buddy"  style={{ paddingTop: "8vh" }}>
                                        The BIG Question
                                    </div>
                                    <div className="elt-wrap">
                                    <div className="extra-large-text" style={{ marginBottom: "12vh", color: "#6582BC" }}>
                                        How could we create an informational platform to improve hikers' safety, trail searching, 
                                        and hiking experience?
                                    </div>
                                    </div>

                                    <img src={divider} className="divider" width="400rem" alt="divider" 
                                    style={{ transform: "scaleX(-1)"}}></img>
                                </Fade>
                                <Fade>
                                    <div className="extra-large-text-buddy" style={{ marginTop: "20vh" }}>
                                        Our Solution | Introducing Nature Finder
                                    </div>

                                    <div className="extra-large-text" style={{marginBottom: "10vh", color: "#6582BC" }}>
                                        A Trial Finding Platform Curated for Hikers
                                    </div>

                                    <div className="product-overview">
                                    <Fade>
                                        <div>
                                            <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#98b5ef" }}>Explore Trails</h2>
                                            <LazyLoad offsetBottom={400}>
                                            <img src={Explore} width="250.8rem" height="469.68rem" alt="explorefeature"
                                            style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "4vh"}}></img>
                                            </LazyLoad>
                                            <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                            This feature allows hikers to find trails based on their preferences with the use of shortcuts, sorting, 
                                            and filtering. Hikers can also download trail maps to assist them while hiking.
                                            </p>
                                        </div>

                                    </Fade>

                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#98b5ef" }}>Navigate Trails</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Map} width="250.8rem" height="469.68rem"alt="mapfeature"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                        Nature Finder offers real-time crowdsourced hazard reporting data that permits accurate displays of potential 
                                            hazard when navigating to trails. Hikers are also prompted to report hazards as it contributes points to rewards.
                                        </p>
                                    </div>
                                    </Fade>

                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#98b5ef" }}>Incentivise Usage</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Rewards} width="250.8rem" height="469.68rem"alt="rewardsfeature"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ paddingBottom: "16vh", textAlign: "center" }} >
                                        User research highlighted that people hike to maintain and/or improve their health. This feature is designed for this 
                                        desire and also prompts users to report hazards, as it rewards users for hiking trails and reporting hazards in forms of 
                                        badges and real rewards. 
                                        </p>
                                    </div>
                                    </Fade>
                                    </div> 

                                    </Fade>
                                    <Fade bottom>
                            <LazyLoad offsetBottom={600}>  
                                <img src={divider} className="divider" width="400vw" alt="divider" ></img>
                            </LazyLoad>
                                <div className="extra-large-text-buddy" 
                                style={{ marginTop: "16vh" }}>
                                    The Process
                                </div>

                                <div className="extra-large-text" style={{ color: "#6582BC" }}>
                                    A fun-hilled journey (pun-intended, I tried)
                                </div>
                                <LazyLoad offsetBottom={600}> 
                                <img src={divider} className="divider" width="400vw" alt="divider" 
                                style={{ marginTop:"16vh", marginBottom: "10vh", transform: "scaleX(-1)"}}></img>
                                </LazyLoad>
                            </Fade>


                        </Element>
                        <Element className="discover">
                                <Fade>
                                    <h1>Discover</h1>
                                    <div className="row">
                                    <div className="col">
                                        <p style={{ marginBottom: "4vh"}}>
                                        To better understand our chosen group, we conducted semi-structured interviews. We interviewed four hikers who hiked on 
                                        average once per month since they would be more familiar with the hiking process. The research focused on users' motivations, 
                                        frustrations, habits, and experiences.
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                                    <img src={discover_1} width="100%" alt="participant quotes"
                                        style={{  marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        Excerpts from interview transcripts
                                    </div>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                        <p style={{ marginBottom: "4vh"}}>
                                        Since the environment is an important consideration for our user group, we also 
                                        conducted a Fly on the Wall observation, where we observed hikers at the Discovery 
                                        Park trail, a very popular trail that is loved by local hikers.
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                                    <img src={discover_2} width="100%" alt="conducting our observation"
                                        style={{  marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        Conducting Fly on the Wall observation at Discovery Park
                                    </div>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                        <p style={{ marginBottom: "4vh"}}>
                                        From our research, we extracted the following major findings: 
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                                    <img src={discover_3} width="100%" alt="main research findings"
                                        style={{  marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        The three main research findings gathered from research
                                    </div>
                                    </div>
                                    </div>
                                </Fade>
                        </Element>
                        <Element className="ideation">
                                <Fade>
                                    <h1>Ideation</h1>

                               
                                    <div className="row">
                                    <div className="col">
                                        <p>
                                        To clarify our research findings, we extracted key findings and constructed two different personas. Key evidences we used to 
                                        help us construct the personas are delineated below: 
                                        </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                               
                                    <img src={ideation_1} width="100%" alt="key evidences"
                                        style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                        
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                    Key evidences used for persona construction
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                        <p>
                                        Using those key findings, we drafted preliminary personas and then proceeded to create the actual ones. The personas 
                                        helped us understand the diverse range of users that our group encompasses and served as critical guidelines 
                                        for designing a product that truly meets users’ needs. 
                                        </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                               
                                    <img src={ideation_2} width="100%" alt="research synthesis and draft personas"
                                        style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                    
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                    Synthesizing research findings, creating preliminary personas
                                    </div>
                                    
                                    <div className="row">
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}>

                               <div className="responsive-wrap">
                               <img src={ideation_3} width="100%" alt="Dora's persona"
                                   style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                </div>
                                   
                               </LazyLoad>
                               </div> 
                           
                                    <div className="col">

                               <LazyLoad offsetBottom={600}> 
                               
                               <img src={ideation_4} width="100%" alt="Michael's persona"
                                   style={{ marginTop: "4vh", marginBottom: "12vh"}}></img>
                                   
                               </LazyLoad>
                               </div>
                               </div>

                               <div className="row">
                                    <div className="col">
                                        <p>
                                        With the personas in mind, we wanted to scrutinize the major pain and touchpoints of the hiking experience. 
                                        So, we created a user journey map. I ensured the colour scheme and layout was clear and concise. 
                                        The visual representation is backed with information gathered from the research stage. 
                                        </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                               
                                    <img src={ideation_5} width="100%" alt="user journey map"
                                        style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                        
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                    User journey map based on Michael's persona
                                    </div>
                                </Fade>
                        </Element>
                         <Element className="design">
                                <Fade>
                                    <h1>Design</h1>

                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        The research and ideation led to the idea of Nature Finder, a crowdsourced mobile platform that ameliorates 
                                        the hiking experience for hikers through:
                                        </p>
                                    </div>
                                    <div className="col">

                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={design_1} width="100%" alt="design requirements"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                               The four key design requirements
                               </div>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        In addition to the design requirements, we added an incentive program that rewards hikers for engaging with our application. 
                                        This encourages users to engage with the the platform, especially its crowdscource element which relies on user input.
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={design_2} width="100%" alt="reward feature"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                               Adding a reward feature
                               </div>
                               </div>
                               </div>
                               <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        With those concepts in mind, we created individual storyboards to explore the diverse range of components 
                                        our solution could include. The following is my storyboard for a packing checklist feature.
                                        </p>
                                    </div>
                                    <div className="col">

                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={design_3} width="100%" alt="storyboard"
                                   style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                               My photo-based storyboard
                               </div>
                               <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        
                                        After reaching a consensus on the features, we created an information architecture to ensure the 
                                        logical flow of the solution. 
                                        I communicated with the team to ensure the design and content of the information architecture was 
                                        simple, logical, and readable.
                                        </p>
                                    </div>
                                    <div className="col">

                                    </div>
                                    </div>
                               <LazyLoad>
                               <img src={design_4} width="100%" alt="information architecture"
                                   style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                               Our information architecture based on research and ideation
                               </div>
                               
                                </Fade>
                        </Element>
                        <Element className="protoiteration">
                                <Fade>
                                    <h1>Prototype + Iteration</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        The constructed blueprint allowed us to effectively create Nature Finder. We then translated our
                                        design ideas into paper prototypes, 
                                        which allowed a low-cost way to test initial designs. The prototypes were created for our three main tasks
                                        and tested with four users.
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_1} width="100%" alt="testing tasks"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                              The three tasks tested using our paper prototype
                               </div>
                               </div>
                               </div>
                               <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        We used our paper prototypes to conduct usability testing and receive critique prior to creating our wireframes.
                                        </p>
                                    </div>
                                    <div className="col">
                                    
                               </div>
                               </div>
                               <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_2} width="100%" alt="testing process"
                                   style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                              Planning the tests, creating the wireframes, and testing with users
                               </div>
                              
                               <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        The usability testing allowed us to gain insight into our user groups' thoughts and perception regarding out 
                                        proposed solution. The findings served a pivotal role in constructing wireframes for our three main tasks. 
                                        They allowed us to do additional iterations for creating aesthetics and solidifying the interaction flow.
                                        I led the planning process, including setting up the key tasks and planning out how the testing will be conducted. 
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_3} width="100%" alt="major findings"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                              The three major findings from our testing
                               </div>
                               </div>
                               </div>
                               <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_4} width="100%" alt="key path one"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_5} width="100%" alt="key path two"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                               <LazyLoad offsetBottom={600}> 
                               
                               <img src={protoiterate_6} width="100%" alt="key path three"
                                   style={{ marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                                </Fade>
                        </Element>
                        <Element className="hi-fi">
                                <Fade>
                                    <h1>Hi-fi Product</h1>
                                    <div className="row">
                                        <div className="col">
                                        <p> After the critiques, tests, and iterations, we constructed high-fidelity mockups of the product as well as
                                        an interative prototype. I was responsible for the Explore/homepage feature as well as establishing a consistent
                                        visual language and creating showcase artifacts, including the interactive prototype.
                                    </p>
                                        </div>
                                        <div className="col">
                                            
                                        </div>

                                    </div>
                                   
                                    <LazyLoad offsetBottom={600}> 
                               
                               <img src={hifiproto_1} width="100%" alt="hi-fi mockups"
                                   style={{ marginTop: "4vh", marginBottom: "4vh"}}></img>
                                   
                               </LazyLoad>
                                   
                                </Fade>
                        </Element>
                        <Element className="reflection">
                                <Fade >
                                    <h1>Reflection</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh" }}>
                                        <p>
                                        Throughout this entire process of creating NatureFinder, I learnt a lot about executing the UX design process 
                                        and the importance of being passionate about the work you do. In three months, the team also went from being 
                                        total strangers to forming friendships that continued outside of the classroom. Those bonds would not have 
                                        been possible without establishing effective communication and being considerate of one another. There are also 
                                        few things we wish to iterate upon in the future:<br/><br/>1. Conduct more user research <br/><br/>
                                        2. Enhance navigation feature: a timer that would automatically delete temporary hazards, such as “wild animals 
                                        spotted!”; a verification system where users can either upvote or downvote the information they see. <br/><br/>
                                        3. Create a complementary portable navigation extension, such as a smart watch
                                        </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}> 
                               <div className="image-wrap">
                               <img src={reflection_1} width="100%" alt="major findings"
                                   style={{ marginBottom: "4vh"}}></img>
                                   </div>
                                   
                               </LazyLoad>
                               <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                Team Fantastic 4 
                               </div>
                               </div>
                               </div>
                                </Fade>
                        </Element>
                        <footer>
                            <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~</h1><br/>
                                    <div className="footer-wrap">
                                    <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #FFD601 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/yummly"
                                rel="noopener noreferrer">Yummly</a>
                              
                           
                                </div>
                                </div> 
                                </Fade>
                    
                    
            </footer>
            <Fade bottom> 
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}}  target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #98B5EF 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Blue Heart" style={{ fontSize: "inherit" }}>💙</span> 
                    </div>
                    </Fade>
                    </div>

                </Fade>
            </div>
            </div>
        );
    }
};

render(<NatureFinder/>, document.getElementById('root'));

export default NatureFinder

/*      <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #F47A20 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw"}}
                                href="/yirental"
                                 rel="noopener noreferrer">Yirental</a>   */