import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import i_1 from './initiative_1.jpg'
import i_2 from './initiative_2.png'
import i_3 from './initiative_3.jpg'
/*4033B5*/
/*F47A20*/
/* <LazyLoad offsetBottom={100}>
                                    <img src={p1_1} width="100%" alt="design recommendation example"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    */
class USAFacts extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #E62790  20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="usafacts-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #E62790  20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}} className="Initiative-1" to="initiative-1" spy={true} smooth={true} duration={500}>1. Established UXR</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}} className="Initiative-2" to="initiative-2" spy={true} smooth={true} duration={500}>2. Executed UXR</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}} className="Initiative-3" to="initiative-3" spy={true} smooth={true} duration={500}>3. Evangelized UXR</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}} className="Reflection" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #E62790  20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #E62790  20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label='Red Heart' style={{ fontSize: "inherit" }}>❤️</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#E62790"}}>
                        USAFacts: Accessible Data for All <br/> 
                        </div> 
                        <div className="projectpage-type" >
                        UX Research + Strategy
                        </div> 

                        <Element className="overview" >
                                <Fade bottom>
                                    <h1 style={{ color: "#E62790" }}>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                        <p>
                                            USAFacts is a responsive website solution that aims to make U.S. government data accessible and understandable for the public.
                                             Due to my non-disclosure agreement, I will not be going into details about the specific projects, instead, 
                                             I will summarize my three major initiatives as the first and sole UX researcher in the organization. To learn more about my work,
                                              please feel free to email me: <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit", color: "#242424", backgroundImage: "linear-gradient( white 80%, #E62790  20%)"}}
                                href="mailto:amandazhu9810@gmail.com" 
                                target="_blank" rel="noopener noreferrer">amandazhu9810@gmail.com</a>
                                              . 
                                        </p> <br/>
                                    </div>

                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#00347B"}}>Role</h2>
                                        <p> Sole + First UX Researcher </p>
                                        <h2 style={{ color: "#00347B" }}>Duration</h2>
                                        <p> Mar 2021 to Sep 2022 (1 year and 6 months)</p>
                                        <h2 style={{ color: "#00347B" }}>Tools</h2>
                                        <p>User Zoom | Miro | Micrsoft Suite | Figma | Google Analytics | Google Optimize | Mailchimp | HotJar</p>

                                    </div>
                                    </div>

                                </Fade>
                        </Element>
                    
                        <Element className="initiative-1">
                                <Fade>

                                    <h1 style={{ color: "#E62790" }}>1. Established UX research processes</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>
                                    <p>
                                    I worked cross-functionally and with the leadership team to establish UX research processes and integrate them into the product development process. 

                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#00347B"}}>
                                    Major Outcomes
                                    </h2>
                                    <p>
                                    Integrated process with the product development cycle</p><br></br>
                                    <p>Standardized documentation process including general research process guide, research plan and report templates, guidelines for methods, 
                                        creating internal participant pool, recruitment and reward processes. Documentation has been used and validated by new members of the UX team</p>
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={i_1} width="100%" alt="snapshot from elections feature"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Sample participant pool enrollment confirmation email that I set up as part of the sign-up flow
                                    </div>
                                    </div>
                                   
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>


                            <Element className="initiative-2">
                                <Fade>

                                    <h1 style={{ color: "#E62790" }}>2. Executed UX research studies</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>
                                    <p>
                                    I created and executed all UX research studies, ranging from generative to evaluative studies, 
                                    and often combining quantitative and qualitative methods.

                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#00347B"}}>
                                    Major Outcomes
                                    </h2>
                                    <p>
                                    Executed 20+ studies, including:</p> <br></br>
                                    <p>Helped marketing optimize the newsletter subscription experience using desk research and survey leading to  96.86% more 
                                        total signups and 178.0% increase in conversion rate (Year over Year comparison)</p><br></br>
                                 <p>Created guidance for revamping the web navigation using concept testing, click tests, tree tests, and usability testing, the 
                                    new navigation is currently under leadership review</p><br></br>
                                    <p>Determinined design direction using quantitative data to drive design directions using Google Optimize (A/B tests) 
                                        and HotJar (heatmaps)</p>
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={i_2} width="100%" alt="snapshot from elections feature"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        One of the (cool) newly launched features I helped concept test, check out the feature <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit", color: "#242424", backgroundImage: "linear-gradient( white 80%, #E62790  20%)"}}
                                href="https://usafacts.org/data-projects/2022-midterm-map" 
                                target="_blank" rel="noopener noreferrer">here</a>
                                    </div>
                                    </div>
                                   
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>

                            <Element className="initiative-3">
                                <Fade>

                                    <h1 style={{ color: "#E62790" }}>3. Evangelized UX research</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>
                                    <p>
                                    I helped evangelize UX research (and UX, generally) throughout the organization through brownbags, 
                                    presentations, and discussions. 

                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#00347B"}}>
                                    Major Outcomes
                                    </h2>
                                    <p>
                                    Idea of UX research shared with leadership team and cross-functional team</p> <br></br>
                                    
                                 <p>Team members started reaching out to collaborate with UX research</p> <br></br>
                                    <p>Increase inquiries and thoughts from a user-centered perspective during meetings</p><br></br>
                                    <p>Creation and establishment of a baseline benchmark study to measure UX longitudinally</p>
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={i_3} width="100%" alt="croppped Slack message"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                       My manager's parting words 
                                    </div>
                                    </div>
                                   
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>

                            <Element className="reflection">
                                <Fade>

                                    <h1 style={{ color: "#E62790" }}>Reflection</h1>
                                    

                                    <div className="row">
                                   
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                    <h2 style={{ marginTop: "0", color: "#00347B"}}>
                                    1. Collaboration + alignment is crucial
                                    </h2>
                                    <p>
                                    Ensuring the success of research projects is heavily dependent on being on the same page with stakeholders. Always set up sync ups when appropriate.
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#00347B"}}>
                                    2. UX as a problem-solving approach
                                    </h2>
                                    <p>
                                    I realized that UX is a powerful approach for solving problems, and research and design methods are like building blocks and tools to help solve the problems. 
                                    </p>

                                    <h2 style={{ marginTop: "4vh", color: "#00347B" }}>
                                    3. The art of communication
                                    </h2>
                                    <p style={{ marginBottom: "4vh" }}>
                                    I dedicated some of my time there setting up 1:1s with team members to understand their perspectives. Understanding their goals and pain points played a critical role in 
                                    ensuring a smooth collaboration and presentation process, as I was able to understand how to see the research I am doing from their perspective and focus on “what’s in it for me” from their viewpoint.
                                    </p>
                                    <p>
                            


                                    </p>
                                    
                                    </div>

                                    <div className="col">
                               
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>
                            <footer>
                                <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~</h1><br/>
                                    <div className="footer-wrap">
                                    
                                      <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #3E41A8 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/cocobot"
                                rel="noopener noreferrer">CocoBot</a> 
                                
                                </div>
                                </div> 
                                </Fade>
                    
            </footer>
            <Fade bottom>
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #E62790 20%)"}}target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Red Heart" style={{ fontSize: "inherit" }}>❤️</span> 
                    </div>
                    </Fade>
                    </div>
                    
                </Fade>
                
            </div>
            </div>
        );
    }
};

render(<USAFacts/>, document.getElementById('root'));

export default USAFacts

/*  Over the course of my time at USAFacts, I had grow tremendously as a UX researcher, some of my main takeaways included: */

/* on my last day (<span role="img" aria-label="Crying Face" style={{ fontSize: "inherit" }}>😢</span>)  */
