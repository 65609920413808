import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './uwlabs.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
/*light B3A9D8*/
/*dark 4b2e83*/


class UWLabs extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="uwlabs-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} className="KidsTeam1" to="kidsteam1" spy={true} smooth={true} duration={500}>1. Remote Collaboration</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} className="KidsTeam2" to="kidsteam2" spy={true} smooth={true} duration={500}>2. WYR for Research</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} className="HINTS1" to="HINTS1" spy={true} smooth={true} duration={500}>3. VR Nature Restoration</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} className="HINTS2" to="HINTS2" spy={true} smooth={true} duration={500}>4. Covid-19 x Outdoors</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Purple Heart" style={{ fontSize: "inherit" }}>💜</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#4b2e83"}}>
                        Explorations in HCI Labs<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        Academic Research
                        </div> 

                        <Element className="overview">
                                <Fade bottom>
                                    <h1 style={{ color: "#B3A9D8"}}>Overview</h1>
                                    <div className="row">
                                        <div className="col">
                                    <p>
                                Fascinated by human's interactions with technology and the external environment, I took the intiative to delve deeper into the field of HCI through 
                                participating in multiple research groups and labs. The two labs I've been the most involved in are the <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}
                                href="https://depts.washington.edu/hints/"
                                target="_blank" rel="noopener noreferrer">UW Human Interaction with Nature and Technological 
                                Systems (HINTS) lab</a> and the <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}
                                href="https://www.kidsteam.ischool.uw.edu/"
                                target="_blank" rel="noopener noreferrer">UW KidsTeam </a>
                                 at the UW Digital Youth Lab. 
                                </p> <br/>
                                <p>
                                I will be detailing the research I have conducted here. So, check back in a bit. In the meantime, feel free to check out two paper I co-authored
                                 that got published recently. <span role="img" aria-label="SmileyFace"></span>😊<br/> <br/></p>
                                 <p>
                                Lee, K.J., Roldan, W., Zhu, T.Q., Saluja, H.K., Na, S., Chin, B., Zeng, Y., Lee, J.H., & Yip, J.C. <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}
                                href="https://dl.acm.org/doi/10.1145/3411764.3445715"
                                target="_blank" rel="noopener noreferrer">The Show Must Go On: A conceptual model of conducting synchronous 
                                participatory design with children online.</a> Proceedings of the 2021 CHI Conference on Human Factors in Computing Systems. Association for Computing Machinery 
                                **Best Paper Award (Top 1%)** <br/> <br/>
                                Cobb, C., Simko, L., Chin, B., Na, S., Saluja, H.K., Zhu, T.Q., Hiniker, A., & Yip, J.C.  <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}
                                href="https://dl.acm.org/doi/abs/10.1145/3459990.3460708"
                                target="_blank" rel="noopener noreferrer">Would You Rather: A focus group method for eliciting and discussing formative 
                                design insights with children.</a> Preparation for Proceedings of the 2021 IDC Interaction Design and Children. Association for Computing Machinery.
                                
                               
                                
                                </p> <br/>
                                </div>
                                <div  className="col">
                                    
                                </div>
                                </div>
                                    
                                </Fade>
                        </Element>
                        <footer>
                            <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~</h1><br/>
                                    <div className="footer-wrap">
                                  
                                 <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #E62790 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw"}}
                                href="/usafacts"
                               rel="noopener noreferrer">USAFacts</a> 
                                </div>
                                </div>
                                </Fade> 
                    
                    
            </footer>
            <Fade bottom> 
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}>
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" target="_blank" rel="noopener noreferrer" style={{backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)"}}>
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Purple Heart" style={{ fontSize: "inherit" }}>💜</span>
                    </div>
                    </Fade>
                    </div>
                   
                </Fade>
            </div>
            </div>
        );
    }
};

render(<UWLabs/>, document.getElementById('root'));

export default UWLabs
