import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './yirental.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import p1_1 from './p1_1.jpg'
import p2_1 from './p2_1.png'
import p3_1 from './p3_1.png'
/*4033B5*/
/*F47A20*/

/* style={{ fontWeight: "700"}} */
class Yirental extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #F7A263  20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="yirental-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263  20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} className="Section 1" to="project-1" spy={true} smooth={true} duration={500}>1. Core Feature Redesign</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} className="Section 2" to="project-2" spy={true} smooth={true} duration={500}>2. Homeowner Suite</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} className="Section 3" to="project-3" spy={true} smooth={true} duration={500} >3. UX Content Guide</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} className="Section 4" to="project-4" spy={true} smooth={true} duration={500}>4. ID Verification System</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} className="Reflection" to="intern-reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #F7A263  20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #F7A263  20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label='Orange Heart' style={{ fontSize: "inherit" }}>🧡</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#F47A20"}}>
                        Yirental: Rental Platform Localization<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        Product Design
                        </div> 

                        <Element className="overview" >
                                <Fade bottom>
                                    <h1 style={{ color: "#F47A20" }}>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                        <p>
                                        Yirental is the largest rental platform for international students currently residing in the U.S. For the upcoming quarters, 
                                        the agile startup aims to expand the platform by growing its U.S. user base. 
                                        My main focus was to spearhead their localization efforts through the use of appropriate UX 
                                        methodologies while working collaboratively with the CEO, development, marketing, and design teams.   
                                        </p> <br/>
                                        <p >
                                        This page provides overviews on the four core projects I initiated/contributed to. I would
                                        be happy to discuss them in more detail. Please reach out to me at <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", color: "inherit"}}
                                href="mailto:amandazhu9810@gmail.com"
                                target="_blank" rel="noopener noreferrer">amandazhu9810@gmail.com</a> <br/> <br/>
                                        </p>
                                    </div>

                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#F7A263"}}>Role</h2>
                                        <p> UX Researcher | Product Designer </p>
                                        <h2 style={{ color: "#F7A263" }}>Duration</h2>
                                        <p> Jun 2020 to Mar 2021 (10 months)</p>
                                        <h2 style={{ color: "#F7A263" }}>Tools</h2>
                                        <p>Google Suite | Figma | Miro | Maze</p>
                                    </div>
                                    </div>

                                </Fade>
                        </Element>

                        <Element className="project-1">
                                <Fade>

                                    <h1 style={{ color: "#F47A20" }}>1. Redesigning the Core Roommate Matching Feature</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>

                                    <h2 style={{ marginTop: "0", color: "#F7A263"}}>
                                    Rationale
                                    </h2>
                                    <p>
                                    During design reviews and walkthroughs of the current roommate matching feature. I realized that the original flow could be 
                                    made more efficient, more catered towards the newly defined target user group — students and young professionals between the 
                                    age of 20 to 27, and more integrated with the current platform. Hence, I initiated and led the redesigning of the roommate matching feature.
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263"}}>
                                    Process
                                    </h2>
                                    <p>
                                    The process involved conducting surveys (n=96) and interviews (n=8), creating a user flow, and designing mid-fidelity 
                                    mobile version wireframes and high-fidelity mockups based on critiques and focus group (n=8) feedback. Upon the refinement of the 
                                    mobile version, I designed the web version with a UI designer.</p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263" }}>
                                    Outcome
                                    </h2>
                                    <p style={{ marginBottom: "4vh"}}>
                                    Based on internal testing,
                                    the efficiency, operationalized by the 
                                    time on tasks, increased by around ~40%. Users reflected that the process was "easy, fast, and efficient" and "very clean and 
                                    easy to use". The app and web versions were handed off to the development team and are currently under development. 
                                    </p>
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={p1_1} width="100%" alt="design recommendation example"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Hi-fi snippets of the final design
                                    </div>
                                    </div>
                                   
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>

                            <Element className="project-2">
                                <Fade>

                                    <h1 style={{ color: "#F47A20" }}>2. Researching + Crafting a Property Management Suite</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>

                                    <h2 style={{ marginTop: "0", color: "#F7A263"}}>
                                    Rationale
                                    </h2>
                                    <p>
                                    A core service of our rental platform is to provide efficient and useful services for property managers
                                    and homeowners. To optimize and unify our existing features, a fellow Product Designer and I decided to 
                                    initiate a large-scale design of a centralized property management platform. I decided to use this opportunity
                                    to hone my research skills through leading the UX research efforts. 
                                    </p>
                                   
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263"}}>
                                    Process
                                    </h2>
                                    <p>
                                    The process began with project scope planning and scrutinizing current features and how they could be optimized/connected. I then collaborated
                                    with my partner to create prototypes and materials for unmoderated usability testing with users (n=10). </p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263" }}>
                                    Outcome
                                    </h2>
                                    <p style={{ marginBottom: "4vh"}}>
                                    The final report was presented to stakeholders and the CEO, design revisions were made and handed off to the develpoment team. The findings also catalyzed a navigation bar revamp, the new version has been publsihed to the live site.</p>
                                    <p>
                                    Check out the presentation deck here:
                                    </p>
                                    <a href="   https://drive.google.com/file/d/1DXoASTj9jOQm10yGlTxAYcxjOP1CT2bz/view" target="_blank" rel="noopener noreferrer">
                                        <button className="button" style={{ marginBottom: "4%" }}> Presentation Deck </button>
                                    </a>

                                 
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={p2_1} width="100%" alt="navigation bar revision"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Before and after of the website navigation bar
                                    </div>
                                    </div>
                                
                                  </div>
                                  </div>
                                   

                                </Fade>
                            </Element>

                            <Element className="project-3">
                                <Fade>

                                    <h1 style={{ color: "#F47A20" }}>3. Creating a Guide for Ensuring Language Consistency</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>

                                    <h2 style={{ marginTop: "0", color: "#F7A263"}}>
                                    Rationale
                                    </h2>
                                    <p>
                                    Two of the first tasks I conducted upon joining the start-up were conducting a cognitive walkthrough of its platform 
                                    and understanding both the mobile and desktop platforms. From those tasks, I realized that inconsistencies could 
                                    mislead or confuse users and there lacks a tool that ensures consistencies across the platform. Here is the 
                                    cognitive walkthrough I presented:
                                    </p>
                                    <a href="https://drive.google.com/file/d/1X_tphurFtsJw-nJ-3p1onRY6bx2FC7Gp/view" target="_blank" rel="noopener noreferrer">
                                        <button className="button" style={{ marginBottom: "4%" }}> Cognitive Walkthrough </button>
                                    </a>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263"}}>
                                    Process
                                    </h2>
                                    <p>
                                    Thus, I initiated the efforts to create Yirental content guides, including a UX writing guide working alongside the 
                                    marketing and design teams as well as reviewing all of the mobile and desktop platforms’ contents (~300 mockups).</p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263" }}>
                                    Outcome
                                    </h2>
                                    <p style={{ marginBottom: "4vh"}}>
                                    The first versions of the content guides have been approved by the CEO and reviewed by all members of the Yirental teams. 
                                    The UX writing guide has become a critical reference point for the design team. The identified language issues from the 
                                    reviews have been fixed by the developer team and contributed to the efforts of increasing language consistencies by ~80% 
                                    and doubling the number of domestic users.
                                    </p>
                                    </div>

                                    <div className="col" style= {{ textAlign: "center", paddingTop: "4vh"}}>
                                    <div className="col-image-wrap">
                                    <LazyLoad offsetBottom={100}>
                                    <img src={p3_1} width="100%" alt="snippets of guide"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Snippets of the UX writing guide
                                    </div>
                                    </div>
                                
                                  </div>
                                    </div>

                                </Fade>
                            </Element>

                            <Element className="project-4">
                                <Fade>

                                    <h1 style={{ color: "#F47A20" }}>4. Optimizing for an Appropriate ID Verification System</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "8vh"}}>

                                    <h2 style={{ marginTop: "0", color: "#F7A263"}}>
                                    Rationale
                                    </h2>
                                    <p>
                                    The rental platform is marketed as a “trustworthy” platform, thus its products should reflect such. 
                                    With the integration of the people and connections feature, trust has also become an increasingly 
                                    important factor to consider. Those realizations led my co-worker and I to take on the critical 
                                    task of designing an appropriate ID verification system. 
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263"}}>
                                    Process
                                    </h2>
                                    <p>
                                    The process began with a literature review of ID verification systems as well as a competitive analysis of 
                                    thirteen popular apps across three different sectors: social, service, and finance. </p>
                                    <h2 style={{ marginTop: "4vh", color: "#F7A263" }}>
                                    Outcome
                                    </h2>
                                    <p>
                                    We coordinated with marketing about the next steps of the project. Here is the internal
                                    report we produced for the project.
                                    </p>
                                    <a href="https://drive.google.com/file/d/1_fl8W98k6NI-DvMD1g7YEwccwxJRvhhd/view?usp=sharing" 
                                    target="_blank" rel="noopener noreferrer">
                                        <button style={{ marginBottom: "10%" }} className="button" > Internal Report </button>
                                    </a>
                                    
                                    </div>

                                    <div className="col">
                               
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>
                            <Element className="intern-reflection">
                                <Fade>

                                    <h1 style={{ color: "#F47A20" }}>Reflection</h1>
                                    <div className="row">
                                    <div className="col">
                                    <p>
                                    It was through my time at Yirental that I discovered my passion for UX research. As such, I shifted my focus and dedicated all of my time there to integrate UX research into 
                                    the product development process and collaborating with product designers on research for their projects. I learnt a tremendous amount about collaboration and working as the 
                                    sole UX researcher. It was also deeply satisfying 
                                    to see all of my effort come together to help build a human-centered product line for users. 
                                    </p>
                                    
                                    </div>

                                    <div className="col">
                               
                                    </div>
                                
                                  
                                    </div>

                                </Fade>
                            </Element>
                            <footer>
                                <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~</h1><br/>
                                    <div className="footer-wrap">

                                    <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #98b5EF 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/naturefinder"
                                rel="noopener noreferrer">Nature Finder</a> 

                                    
                                    
                                      
                                
                                </div>
                                </div> 
                                </Fade>
                    
            </footer>
            <Fade bottom>
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #F7A263 20%)"}}target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Orange Heart" style={{ fontSize: "inherit" }}>🧡</span> 
                    </div>
                    </Fade>
                    </div>
                    
                </Fade>
                
            </div>
            </div>
        );
    }
};

render(<Yirental/>, document.getElementById('root'));

export default Yirental



/*<a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #FFD601 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/yummly"
                                rel="noopener noreferrer">Yummly</a>  */