import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
import { NavLink } from "react-router-dom"



class Project extends Component {
    render() { 
        return ( 
        <Fade>
        <div className='project'>
            <div className="cover-page">
                <NavLink exact to= { this.props.link } >
                    <img src= {this.props.imageSrc} alt="project-cover">
                    </img>
                </NavLink>
            </div> 
            <div className="project-title">
                { this.props.title } 
            </div>
            <div className="project-subtitle">
                { this.props.subtitle }
            </div>

        </div> 
        </Fade>
        )
    }
}
 
export default Project;
/*
            
            <a href={ this.props.link } target="_blank">
                <button className="project-button" > View Project </button>
            </a>
*/