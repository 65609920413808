import React from 'react'
import '../App.css'
import Fade from 'react-reveal/Fade'


function Error() {
    return (
        <div>
            <Fade bottom>
                <div className="content-wrap">
                    <text className="intro-title" style={{ marginTop: "4vh"}}>
                    Sorry, the page you are looking for is not found <span role="img" aria-label="SadFace" style={{ fontSize: "inherit"}}>😔</span><br/> 
                    </text>
                    <Fade bottom>
                    <a href={ '/' } >
                        <button className="button" style={{}}> Return to Home </button>
                     </a>

                    </Fade>
                </div>                     
            </Fade>
        </div>    
    )
}

export default Error