import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'



class Project extends Component {
    render() { 
        return ( 
        <Fade>
        <div className='project'>
            <div className="cover-page_2">
                <a href= { this.props.link } >
                    <img src= {this.props.imageSrc} alt="Project Cover">
                    </img>
                </a>
            </div> 
            <div className="project-title2">
                { this.props.title } 
            </div>
            <div className="project-subtitle2">
                { this.props.subtitle }
            </div>
        </div> 
        </Fade>
        )
    }
}
 
export default Project;