import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './yummly.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import overview_1 from './overview_1.png'
import define_1 from './define_1.png'
import define_2 from './define_2.png'
import preparation_1 from './preparation_1.png'
import preparation_2 from './preparation_2.png'
import preparation_3 from './preparation_3.png'
import preparation_4 from './preparation_4.png'
import preparation_5 from './preparation_5.jpg'
import analysis_1 from './analysis_1.png'
import analysis_2 from './analysis_2.jpg'
import results_1 from './results_1.jpg'
import results_2 from './results_2.png'
import results_3 from './results_3.png'
import results_4 from './results_4.png'
import results_5 from './results_5.png'
import results_6 from './results_6.png'
import results_7 from './results_7.png'

/* lighter FFD601*/
/* dark 52A29F*/


class Yummly extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #FFD601 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="yummly-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Define" to="define" spy={true} smooth={true} duration={500}>Define Problem Space</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Preparation" to="preparation" spy={true} smooth={true} duration={500}>Test Preparation</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Data analysis" to="data analysis" spy={true} smooth={true} duration={500}>Data Analysis</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Results" to="results" spy={true} smooth={true} duration={500}>Results</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} className="Reflection" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Yellow Heart" style={{ fontSize: "inherit" }}>💛</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#52A29F"}}>
                        Yummly: Website Evaluation<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        Usability Testing
                        </div> 

                        <Element className="overview">
                                <Fade bottom>
                                <h1 style={{ color: "#52A29F" }}>Overview</h1>
                                <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                        <p>
                                        This project focused on evaluating <a 
                                className="hyperlink-text"style={{  textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #FFD601 20%)", marginBottom: "1vh"}}
                                href="https://www.yummly.com/"
                                 rel="noopener noreferrer">Yummly</a>, a website providing personalized guidance throughout the cooking experience, 
                                        to uncover usability improvements. The project was conducted over the course of three months and focused on
                                         the usability process, from recruitment to testing to making actionable recommendations. 
                                       
                                        </p> 
                                    </div>

                                    <div className="col" style={{  marginBottom: "16vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#FFD601"}}>Role</h2>
                                        <p> UX Researcher </p>
                                        <h2 style={{ color: "#FFD601" }}>Duration</h2>
                                        <p> Sep to Dec 2019 (3 months)</p>
                                        <h2 style={{ color: "#FFD601" }}>Team</h2>
                                        <p>Kathy Bui | Shangyang Chen | Seungwon Shin</p>
                                        <h2 style={{ color: "#FFD601" }}>Tools</h2>
                                        <p>Google Suite | UX Cloud | Miro | Figma</p>
                                    </div>
                                    </div>
                                    
                                    <Fade>
                                
                                    <div className="extra-large-text-buddy" style={{ marginTop: "10vh" }}>
                                        Before we "dig" in...
                                    </div>

                                    <div className="extra-large-text" style={{marginBottom: "10vh", color: "#52A29F" }}>
                                        Let me explain Yummly
                                    </div>
                                    </Fade>
                                    <div className="row">
                                        <div className="col">
                                    <LazyLoad offsetBottom={100}>
                                        <div className="image-wrap">
                                            <img src={overview_1} width="100%" alt="yummly's website"
                                            style={{ marginBottom: "4vh"}}></img>
                                            </div>
                                            </LazyLoad>
                                            
                                            </div>
                                            
                                            <div className="col" style={{ marginBottom: "16vh"}}>
                                            <p style={{ paddingBottom: "6vh"}} >
                                        Yummly is a website that offers recipe recommendations, discoveries, nutritional values, online grocery shopping, and meal planning. 
                                        It also has a special meal planner that allows users to save recipes and shop for their ingredients online. 
                                        In addition, Yummly offers a collections feature that allows users to create folders that keep their recipes organized. <br/> <br/>
                                        As a comprehensive platform, Yummly provides assistance to a wide range of users. From students to working professionals 
                                        to parents, users will all be able to find a favourable recipe suiting their dietary preferences. 
                                    </p>
                                    </div>
                                    </div>
                                   
                               
                                
                                </Fade>
                        </Element>
                        <Element className="define">
                                <Fade>
                                    <h1 style={{ color: "#52A29F" }}>Defining the Problem Space</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>For our usability tests, we wanted to test the effectiveness of the site for college students, 
                                        because developing the habit of cooking for oneself early on is an important part of gaining 
                                        independence and thus makes it the optimal market for Yummly to expand into. <br/> <br/>

                                    To help identify the key goals of the study, we conducted individual cognitive walkthroughs 
                                    and heuristic evaluations. The two methods allowed identifications of potential pain and 
                                    frustration points for our user group. </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={define_1} width="100%" alt="snippets of my research"></img>
                                    </LazyLoad>
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Snippet of my cognitive walkthrough + heuristic evaluation
                                    </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>Upon discussing the findings of our individual research, we 
                                        extracted the following main goals for the study: </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "12vh"}} src={define_2} width="85%" alt="the three key goals"></img>
                                    </LazyLoad>
                                     
                                    
                                
                                    
                                </Fade>
                        </Element>
                        <Element className="preparation">
                                <Fade>
                                    <h1 style={{ color: "#52A29F" }}>Preparing for Testing</h1>
                             
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>Our ideal participant breakdown included users who are currently college students between the ages of 18 to 25, 
                                        not studying or working in UX, lived off-campus, did not have experience using Yummly, 
                                        cooked for themselves, and had recording capabilities on their personal computers. 
                                        We sent out questionnaires and used a screener grid to keep track of our data. We 
                                        ended up with six participants that fitted our data and signed our consent form.   </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={preparation_1} width="100%" alt="participant breakdown"></img>
                                    </LazyLoad>
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Breakdown of final participants
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>To keep the format of our study consistent across participants, 
                                        we followed a set of standard procedures. We also decided on logistics such as location, time, and place. </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={preparation_2} width="100%" alt="testing procedure"></img>
                                        
                                    </LazyLoad>
                                    <LazyLoad offsetBottom={600}>
                                    <img style={{ marginBottom: "12vh"}} src={preparation_3} width="100%" alt="testing logistics"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>In addition, we decided on our list of questions utilized through 
                                        the study and the data that will be collected as a result. </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={preparation_4} width="100%" alt="data to be collected"></img>
                                        
                                    </LazyLoad>

                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>We then constructed the tasks based on our research goals. When conducting each usability study, 
                                        I implemented the idea of rotating the task order to prevent bias. For example, 
                                        Participant 1 would complete tasks in the order of 1, 2, 3, 4 while Participant 2 would 
                                        complete tasks in the order of 3, 4, 1, 2. The tasks constructed were: </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={preparation_5} width="100%" alt="finalized tasks"></img>
                                        
                                    </LazyLoad>

                                    </div>
                                    </div>
                                
                                     
                                    
                                </Fade>
                        </Element>
                        <Element className="data analysis">
                                <Fade>
                                    <h1 style={{ color: "#52A29F" }}>Data Analysis</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>Upon completing the usability testing, we began analyzing the results. 
                                        The first step was making sense of the data we have collected. Notes 
                                        were cleaned up while video recordings were transcribed and quantitative 
                                        data were extracted. Appropriate statistics, such as geometric mean, were also calculated. </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={analysis_1} width="100%" alt="snippets of data collected"></img>
                                    </LazyLoad>
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Some of the data we organized for analysis
                                    </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>The data was then ready for analysis! We successfully extracted meaningful 
                                        information and identified patterns and trends through affinity diagramming and thematic mapping.  </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={analysis_2} width="100%" alt="thematic mapping"></img>
                                    </LazyLoad>
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Miro board I constructed to help the analysis process
                                    </div>
                                </Fade>
                        </Element>
                        <Element className="results">
                                <Fade>
                                    <h1 style={{ color: "#52A29F" }}>Results + Recommendations</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p>Based on the analysis, we drew the following major findings. I took the initiative to organize and delineate our findings. For a comprehensive list of findings 
                                        (negative and positive) and details about the research, please <a 
                                className="hyperlink-text"style={{  textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #FFD601 20%)", marginBottom: "1vh"}}
                                href="https://drive.google.com/file/d/15DD-fPOPRMLwhwnCIJcdSXjLRjLh2gWR/view?usp=sharing"
                                 rel="noopener noreferrer">click here</a>. 
                                        The negative results were ranked based on the following severity rating (adapted from <a 
                                className="hyperlink-text"style={{  textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #FFD601 20%)", marginBottom: "1vh"}}
                                href="https://g.co/kgs/9H6p3a"
                                 rel="noopener noreferrer">Dumas & Redish</a>), from most to least severe: </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{  marginBottom: "4vh"}} src={results_1} width="100%" alt="severity rating used"></img>
                                    </LazyLoad>
                                     
                                    
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#FFD601" }}>Misleading Icons + Terminology (Level 1)</h2>
                                        <p>Throughout Yummly website, there was terminology that was not apparent to our participants. This caused participants to sometimes 
                                            neglect the key action buttons for them to achieve certain goals. Thus, it sometimes resulted in task incompletion, 
                                            leading to the severity rating of one.</p>
                                  
                                    
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={results_2} width="85%" alt="issue one example"></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "4vh"}}>
                                    For example, participants had trouble adding items to their collection because the button was displayed as "Yum"
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h3 style={{ color: "#242424" }}>Recommendation</h3>
                                        <p>Keep naming conventions and icon designs consistent</p>
                                        <div className="pic-caption" style={{ marginBottom: "8vh"}}>
                                    For example, eliminate the Yum button and add a "Add to Collection" feature
                                     to where other "adding" options are found. This will improve consistency across the site.
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "16vh"}} src={results_3} width="85%" alt="issue one recommendation example"></img>
                                    </LazyLoad>
                                    
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#FFD601" }}>Inefficient Interactions (Level 2)</h2>
                                        <p>Additionally, we found that participants experienced frustration with certain features needed to complete a task because the interactions were 
                                            inefficient. This caused significant delays and frustration which is why it was given a severity rating of two. </p>
                                  
                                    
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={results_4} width="85%" alt="issue two example"></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "8vh"}}>
                                    For example, when participants were asked to filter their recipe search,
                                     they could not locate the filter option because it is not present until after the search
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h3 style={{ color: "#242424" }}>Recommendation</h3>
                                        <p>Add useful features to where participants expect to find them</p>
                                        <div className="pic-caption" style={{ marginBottom: "4vh"}}>
                                        For example, allow participants to filter before searching for results by removing “Add Ingredients” on the initial search bar 
                                        and replace with “Filter” to increase the visibility of feature; allows participants put in ingredients they want to 
                                        included or excluded in the recipe
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "16vh"}} src={results_5} width="85%" alt="issue two recommendation example"></img>
                                    </LazyLoad>
                                    
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#FFD601" }}>Mismatch with Expectations (Level 3)</h2>
                                        <p>Moreover, there are certain moments throughout the testing where the user was faced with unexpected situations. 
                                            This connotes a mismatch with participants’ mental models. This finding has a severity rating of three, 
                                            because participants are still able to navigate through the tasks despite the minor hindrance. </p>
                                  
                                    
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={results_6} width="85%" alt="issue three example"></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "8vh"}}>
                                    For example, participants had trouble finding the total price for comparison because every other 
                                    price was oriented on the right while the total price was placed on the left
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h3 style={{ color: "#242424" }}>Recommendation</h3>
                                        <p>Move and re-design objects to better match users' mental models</p>
                                        <div className="pic-caption" style={{ marginBottom: "4vh"}}>
                                        For example, move the total price to the right bottom corner of the screen, where participants thought it was going to be located
                                    </div>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "16vh"}} src={results_7} width="85%" alt="issue three recommendation example"></img>
                                    </LazyLoad>
                                    
                                    </div>
                                    </div>
                                
                                  
                                </Fade>
                        </Element>
                        <Element className="reflection">
                                <Fade>
                                    <h1 style={{ color: "#52A29F" }}>Reflection</h1>
                                    <div className="row" style={{ marginBottom: "24vh"}}>
                                        <div className="col">
                                       <p> It was overall a really fun project and I learnt a lot about the preparation for 
                                           and execution of usability testing. If I was to re-do this project, I would consider the following: <br/> <br/>
                                           1. Increase the robustness of our participant sample through recruiting more participants and avoiding biases  <br/> <br/>
                                           2. Clarify wording and phrasing of tasks by rewording some of the tasks based on potential confusions we observed <br/> <br/>
                                            3. Conduct A/B testing to validate our recommendations</p>

                                        </div>
                                        <div className="col">

                                        </div>
                                    </div>
                                </Fade>
                        </Element>
                        <footer>
                            <Fade bottom>
                      <div style={{ marginTop: "16vh", marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~
                                    </h1><br/>
                                    <div className="footer-wrap">
                                 <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #97C29D 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/starbucks"
                                rel="noopener noreferrer">Starbucks</a>  
                                </div>
                                </div> 
                                </Fade>
                    
                    
            </footer>
            <Fade bottom>
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #FFD601 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Yellow Heart" style={{ fontSize: "inherit" }}>💛</span>
                    </div>
                    </Fade>
                    </div>
                    
                </Fade>
            </div>
            </div>
        );
    }
};

render(<Yummly/>, document.getElementById('root'));

export default Yummly

/*  3. Divide up tasks for more data points. This would have been specifically useful for task four since the task 
                                           contained two features we wanted to test. Due to the merge, we could not use metrics such as time on tasks 
                                           since we were unsure which part of task it served as evidence for */
