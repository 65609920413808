import React from 'react'
import '../App.css'
import { NavLink } from "react-router-dom" 
import { FiLinkedin, FiMail } from 'react-icons/fi'
import { IconContext } from "react-icons"
import logo from './logorevised_1.jpg'
import logo2 from './logorevised_2.jpg'
/*FiExternalLink*/
/*import Fade from 'react-reveal/Fade'*/
/* push to bottom; flex; align bottom; set margin
 <div className="site-title">
                        Amanda Zhu
                        </div>
                        <div className="site-subtitle">
                        UX Research + Design 
                        </div>

                          <a href ="/about">
                                     </a>

CV
<FiExternalLink/>
*/
/*<li><NavLink exact to="/research" activeStyle={{fontWeight: "700", color: "#242424", backgroundSize: "100%"}} >Research</NavLink></li>
*/


function Nav() {
    function hovered(e) {
        e.currentTarget.src = logo2;
      }
      function leave(e) {
        e.currentTarget.src = logo;
      }
    
        return (

            <header className="col-2">
                        <div className="nav-bar">
                    <nav>
                        <div className="site-title">
                      
                        <img src={logo} width="80vw" alt="Site Logo" onMouseOver={hovered} onMouseLeave={leave}></img>
                 
                        </div>
                      
                    <div>
                    <ul>
                        <li><NavLink exact to="/" activeStyle={{fontWeight: "700", color: "#242424", backgroundSize: "100%"}} >Projects</NavLink></li>
                        
                        <li><NavLink exact to="/about" activeStyle={{fontWeight: "700", color: "#242424", backgroundSize: "100%"}}>About</NavLink></li>
                        <li><IconContext.Provider value={{ color: "747474", size: "1.2rem", hover: "color: #242424"}}><a href ="https://drive.google.com/file/d/1kaLtTIeeoqHjrfBQRYgMOI_gywXrpOOD/view?usp=sharing" target="_blank" rel="noopener noreferrer" >
                        Résumé </a> </IconContext.Provider> </li> 
                    </ul>
                    </div> 
                    </nav>
                    <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                    <div className="social-icons">
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FiMail/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" target="_blank" rel="noopener noreferrer">
                            <FiLinkedin/>
                        </a>
                    </div>

                    </IconContext.Provider>
                    <div className="credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Orange Heart" style={{ fontSize: "inherit"}}>🧡</span>
                    </div>
                    </div>
                    
                    
            </header>
    
        )
}

export default Nav

/*
 <ul>
                        <li><a href="/">Projects</a></li>
                        <li><a href="/research">Research</a></li>
                        <li><a href="about">About</a></li>
                        <li><a href="/resume">Resume</a></li>
                    </ul>
                    */