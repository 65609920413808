import usafacts from './card_data/usafacts_r.png'
import cocobot from './card_data/cocobot_r.png'
import yirental from './card_data/yirental_r.png' 
/*import naturefinder from './card_data/naturefinder.jpg'*/
/*  subtitle: 'Creating an assistive platform for caregivers',
Localizing a rental platform with 200k+ users
Curating a mobile platform for hikers */
/* id: 3,
            imageSrc: naturefinder,
            title: 'Nature Finder: Hikers\' Trail Finder',
            subtitle: 'UX Research + Design',
            url: '/naturefinder',
            color: '#98B5EF' */
const projectsData = {
projects: [
    {
        id: 1,
        imageSrc: usafacts,
        title: 'USAFacts: Accessible Data for All',
        subtitle: 'UX Research + Strategy',
        url: '/usafacts',
        color: '#E62790'
     },
     {
        id: 2,
        imageSrc: cocobot,
        title: 'CocoBot: AI-based Chat Therapy',
        subtitle: 'UX Research + Design',
        url: '/cocobot',
        color: '#3E41A8'
     },
     {
        id: 3,
        imageSrc: yirental,
        title: 'Yirental: Rental Service Localization',
        subtitle: 'UX Research + Design',
        url: '/yirental'
    }
   
]
};

export default projectsData;
           