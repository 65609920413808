/*import parkaid from './card_data/parkaid.jpg'*/
import yummly from './card_data/yummly_r.png'
/*import yirental from './card_data/yirental_r.png'*/
import uwlabs from './card_data/uwlabs_r.png' 
import starbucks from './card_data/starbucks_r.png'
import naturefinder from './card_data/nf_r.png'

/*Evaluating a foodie’s website
Building a smart gardening experience
Understanding the waiting line experience
Crafting a seamless parking experience
 imageSrc: yirental,
            title: 'Yirental: Rental Service Localization',
            subtitle: 'UX Research + Design',
            url: '/yirental'
*/

const projects_2_data = {
    projects_2:[
        {
            id: 1,
            imageSrc: naturefinder,
            title: 'Nature Finder: Hikers\' Trail Finder',
            subtitle: 'UX Research + Design',
            url: '/naturefinder',
         },
         {
            id: 2,
            imageSrc: yummly,
            title: 'Yummly: Website Evaluation',
            subtitle: 'Usability Testing',
            url: '/yummly'
        },
        {
            id: 3,
            imageSrc: starbucks,
            title: 'Starbucks: Waiting Line Study',
            subtitle: 'UX Research',
            url: '/starbucks'
         },
        {
            id: 4,
            imageSrc: uwlabs,
            title: 'Explorations in HCI Labs',
            subtitle: 'Academic Research',
            url: '/uwlabs' 
        }

    ]
}

export default projects_2_data; 

/*id: 4,
            imageSrc: parkaid,
            title: 'Parkaid: Parking Re-imagined',
            subtitle: 'Interaction Design',
            url: '/parkaid'*/
