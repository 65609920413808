import React from 'react';
/* import Nav from './components/nav.js' */
import Mobilenav from './components/mobile-nav.js'
import Projects from './components/projects.js'
/*import Research from './components/research.js'*/
import About from './components/about.js'
import Error from './components/error.js'
import USAFacts from './projects/usafacts/usafacts.js'
import CocoBot from './projects/cocobot/cocobot.js'
import Yirental from './projects/yirental/yirental.js'
import NatureFinder from './projects/nf/naturefinder.js'
import Yummly from './projects/yummly/yummly.js'
/*import Plantable from './projects/plantable/plantable.js'*/
import UWLabs from './projects/uwlabs/uwlabs.js'
import Starbucks from './projects/starbucks/starbucks.js'
import Parkaid from './projects/parkaid/parkaid.js'

/*import CV from './components/cv.js'*/
/* <Route path="/plantable" exact component={Plantable} 
<Route path="/research" exact component={Research} /> */

import { BrowserRouter, Route, Switch } from 'react-router-dom'
import './App.css'


/*favicon not showing!!! */
/*text stay highlighted and bolded */
/*try out wiggly line animation*/
/*sticky navbar*/
/* https://freebiesupply.com/blog/css-text-effects-from-codepen/ */
/* https://freefrontend.com/css-link-styles/ */


function App() {

  return (
    <div className="App">
    <BrowserRouter>
       
   
          <Mobilenav/>
          <Switch>
            <Route path="/" exact component={Projects} />
            <Route path="/about" exact component={About}/>
            <Route path="/usafacts" exact component={USAFacts} />
            <Route path="/cocobot" exact component={CocoBot} />
            <Route path="/yirental" exact component={Yirental} />
            <Route path="/naturefinder" exact component={NatureFinder} />
            <Route path="/yummly" exact component={Yummly} />
            
            <Route path="/starbucks" exact component={Starbucks} />
            <Route path="/uwlabs" exact component={UWLabs} />
            <Route path="/parkaid" exact component={Parkaid} />
            <Route component={Error} />
          </Switch>
          
     
    </BrowserRouter>   
    </div>
    
  );
}

/* <Header />
          <Route path="/" exact component={Projects} />
           <Footer /> 
           */

export default App; 
