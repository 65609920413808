import React from 'react'
import '../App.css'
import Fade from 'react-reveal/Fade'
import data from './data.js'
import Card from './card.js'
import data_2 from './data_2.js'
import Card2 from './card_2.js'
import Nav from './nav.js'
import { FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"

/*<p style={{ fontSize:"0.9rem"}}>*Please excuse some messiness as I continue to optimize this portfolio~</p>*/

/*| 你好 | Bonjour  */

function Projects() {
    return (
        <div>
             <Nav/>

        <div className="col-10">
            <div className="content-wrap">
            <Fade bottom>
                <div className="intro-div">
            <div className="intro-title">Hello~ <br/>
            My name is Amanda Zhu<br/><br/></div>
            <div className="intro-subtitle">
            A curious researcher with a background in design | 
            Seattle <span role="img" aria-label="US" style={{ fontSize: "inherit" }}>🇺🇸</span> + Vancouver  
            <span role="img" aria-label="Canada" style={{ fontSize: "inherit" }}> 🇨🇦</span>
                <br/>
            Passionate about using research to create human-centered + practical products
            </div>
            <div className="intro-description">
           
            
            </div>
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials">
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
            </div>
            <Fade bottom>
        <div className='projects-grid'>
                {data.projects.map((project)=>(
                    <Card key={project.id}
                        imageSrc = {project.imageSrc}
                        title = {project.title}
                        subtitle = {project.subtitle}
                        link = {project.url}
                     ></Card>
                ))}
        </div>
        </Fade>
        <Fade bottom>

        <div id="more">
        
        <div className="more-projects-sidenav">
        <a href="#more" style={{fontWeight: "700", fontSize: "1.5rem", color: "#242424"}}> + More Projects</a>
        </div>
        <div className="more-projects">
        + More Projects
        </div>
        <Fade bottom>
        <div className='projects-grid_2'>
                {data_2.projects_2.map((project)=>(
                    <Card2 key={project.id}
                        imageSrc = {project.imageSrc}
                        title = {project.title}
                        subtitle = {project.subtitle}
                        link = {project.url}
                     ></Card2>
                ))}
        </div>
        </Fade>
        </div>
        </Fade>
        <Fade bottom>
        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Orange Heart" style={{ fontSize: "inherit" }}>🧡</span> 
        </div>
        </Fade>
        </Fade>
        </div>
        </div>
        </div>
    )
}

export default Projects
