import React from 'react'
import '../App.css'
import { NavLink } from "react-router-dom" 
/*<li><NavLink exact to="/research" activeStyle={{fontWeight: "700", color: "#242424", backgroundColor: "#FFAE42", backgroundSize: "100%"}} >Research</NavLink></li>
*/

function Mobilenav() {
        return (
            <div className="mobile-nav">
                
                <ul>
                    <li><NavLink exact to="/" activeStyle={{fontWeight: "700", color: "#242424", backgroundColor: "#FFAE42", backgroundSize: "100%"}} >Projects</NavLink></li>
                    
                    <li><NavLink exact to="/about" activeStyle={{fontWeight: "700", color: "#242424", backgroundColor: "#FFAE42", backgroundSize: "100%"}}>About</NavLink></li>
                    <li><a href ="https://drive.google.com/file/d/1kaLtTIeeoqHjrfBQRYgMOI_gywXrpOOD/view?usp=sharing" target="_blank" rel="noopener noreferrer">Résumé</a></li>  
                </ul>
            </div> 
        )
}

export default Mobilenav
