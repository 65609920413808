import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import BeforeAfterSlider from 'react-before-after-slider'
import '../../App.css'
import './cocobot.css'
/*import Footer from '../../components/footer.js'*/
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import divider from './divider.png'
import Onboarding from './Onboarding.gif'
import Home from './Home.gif'
import Progress from './Progress.gif'
import Chat from './Chat.gif'
import Resources from './Resources.gif'
import research_1 from './research_1.jpg'
import research_2a from './research_2a.jpg'
import research_3 from './research_3.png'
import ideation_1 from './ideation_1.gif'
import wireframing_1 from './wireframing_1.jpg'
import wireframing_2 from './wireframing_2.jpg'
import wireframing_3 from './wireframing_3.jpg'
import testing_1 from './testing_1.jpg'
import testing_2 from './testing_2.jpg'
import iteration_1 from './iteration_1.jpg'
import iteration_2a from './iteration_2a.jpg'
import iteration_2b from './iteration_2b.jpg'
import iteration_3a from './iteration_3a.jpg'
import iteration_3b from './iteration_3b.jpg'
import reflection_1 from './reflection_1.png'

/*import color from '../components/data.js' */
/*
import ideation_1 from './ideation_1.gif'
import testing_1 from './testing_1.jpg'
import testing_2 from './testing_2.jpg'
import iteration_1 from './iteration_1.jpg'

import iteration_3a from './iteration_3a.jpg'
import iteration_3b from './iteration_3b.jpg'
*/
/*import outcome_1 from './CocoBot_ProcessBook.pdf'*/
/*
import reflection_1 from './reflection_1.png'
import wireframing_1 from './wireframing_1.jpg'
import wireframing_2 from './wireframing_2.jpg'
import wireframing_3 from './wireframing_3.jpg'
*/

/*, borderRight: "3px solid #787ABD"  */

/*var primary: "#3E41A8"
var accent: "#787ABD"

                            <div className="projectpage-subtitle" >
                            Creating an Assistive Platform for Caregivers
                            </div>    
                              <p style={{ fontWeight: "700"}}>
                                        *Currently finishing up this case study, please excuse some messiness (sorry)!
                                        </p>
*/

class Cocobot extends React.Component {


    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

  

    render() {
        
    
    const before_1 = iteration_2a
    const after_1 = iteration_2b

    const before_2 = iteration_3a
    const after_2 = iteration_3b
   
    

     
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #787ABD 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="cocobot-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 2" to="research" spy={true} smooth={true} duration={500}>Research</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 3" to="ideation" spy={true} smooth={true} duration={500}>Ideation </Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 4" to="wireframing" spy={true} smooth={true} duration={500}>Wireframing</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 5" to="usability-testing" spy={true} smooth={true} duration={500}>Usability Testing</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 6" to="iteration" spy={true} smooth={true} duration={500}>Iteration</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 7" to="outcome" spy={true} smooth={true} duration={500}>Outcome</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} className="Section 8" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Purple Heart" style={{ fontSize: "inherit" }}>💜</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
               

                <div className="col-10">
                    <Fade bottom>
                        <div className="content-wrap">
                            <div className="projectpage-title" style={{ color: "#3E41A8"}}>
                                CocoBot: AI-based Chat Therapy<br/> 
                            </div> 
                            <div className="projectpage-type" >
                            UX Research + Design
                            </div> 
 
                            <div className = "video">
                            <iframe src='https://www.youtube.com/embed/T2qZ_E9vcUI'
                                frameBorder='0'
                                allow='autoplay; encrypted-media'
                                allowFullScreen
                                title='cocobot_video' 
                                style = {{ height:"68vh", width:"100%", paddingTop:"10vh", paddingBottom: "1vh"}}
                            />
                            </div>
                               <div className="pic-caption" style={{ marginTop: "0"}}>
                                            Three-minute project overview
                                        </div>
                                        
                          
                            <Element className="overview">
                                <Fade bottom>
                                    <h1 style={{ color: "#3E41A8"}}>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                        <p>
                                        Caregivers of children with chronic illnesses often face health challenges such as fatigue and disturbed 
                                        sleep due to geographical and financial limitations. CocoBot, an AI-based chatbot, aims to improve the physical 
                                        and mental wellbeing of those caregivers through the Problem Solving Therapy (PST) approach. <br/> <br/>
                                        This University of Washington-sponsored project focuses on the process of making Coco accessible to caregivers through the creation of 
                                        a comprehensive mobile platform.  
                                        </p> <br/>
                                      
                                    </div>

                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#787ABD"}}>Role</h2>
                                        <p>UX Researcher | UX Designer | Sponsor-liaison</p>
                                        <h2 style={{ color: "#787ABD" }}>Duration</h2>
                                        <p>Jan 2020 to Jun 2020 (6 months)</p>
                                        <h2 style={{ color: "#787ABD" }}>Team</h2>
                                        <p>Monina Nepomuceno | Tammy Ho | Derick Yap | Grace Zhu</p>
                                        <h2 style={{ color: "#787ABD" }}>Tools</h2>
                                        <p>Adobe Suite | Google Suite | Figma | Lucid | Miro</p>
                                    </div>
                                    </div>
                                    <img src={divider} className="divider" width="400rem" alt="divider"></img>
                                
                                    <div className="extra-large-text-buddy"  style={{ paddingTop: "8vh" }}>
                                        The BIG Question
                                    </div>
                                    <div className="coco-elt-wrap">
                                    <div className="extra-large-text" style={{ marginBottom: "12vh", color: "#3E41A8" }}>
                                        How might we create a Chatbot-centered mobile application to support the well-being of caregivers for children 
                                        with chronic health conditions?
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}>
                                    <img src={divider} className="divider" width="400rem" alt="divider" 
                                    style={{ transform: "scaleX(-1)"}}></img>
                                    </LazyLoad>
                                    </Fade>

                                    <Fade>
                                    <div className="extra-large-text-buddy" style={{ marginTop: "20vh" }}>
                                        Our Solution | Meet CocoBot
                                    </div>

                                    <div className="extra-large-text" style={{marginBottom: "10vh", color: "#3E41A8" }}>
                                        Taking Care of Caregivers Online since — very soon!
                                    </div>
                                    </Fade>

                                    
                                   
                                    <div className="product-overview">
                                    <Fade>
                                        <div>
                                            <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#787ABD" }}>Onboarding</h2>
                                            <LazyLoad offsetBottom={600}>
                                            <img src={Onboarding} width="250.8rem" height="469.68rem" alt="cocobotonboarding"
                                            style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "4vh"}}></img>
                                            </LazyLoad>
                                            <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                            The introduction carousel gives caregivers a preview of the app's functionalities. The ensuing interactive guiding 
                                            components primes caregivers for chatbot interactions.
                                            </p>
                                        </div>

                                        </Fade>

                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#787ABD" }}>Today</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Home} width="250.8rem" height="469.68rem"alt="cocobottoday"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                        This page reminds caregivers of their upcoming sessions, help track their daily progress through a to-do list, and quickly access 
                                        curated resources. 
                                        </p>
                                    </div>
                                    </Fade> 

                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#787ABD" }}>Chat</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Chat} width="250.8rem" height="469.68rem" alt="cocobotchat"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                        The chat allows caregivers to converse with CocoBot freely for support, recommendations, and resources. The interactive chat components 
                                        break down potentially long conversations. 
                                        </p>
                                    </div>
                                    </Fade>

                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#787ABD" }}>Resources</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Resources} width="250.8rem" height="469.68rem" alt="cocobotresources"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ paddingBottom: "6vh", textAlign: "center" }} >
                                        The SOS button is ubiquitous and provides caregivers with credible emergency resources. Resources are efficiently
                                        accessed through categorization, tagging, search, and filtering.
                                        </p>
                                    </div>
                                    </Fade>
                                    
                                    <Fade>
                                    <div>
                                        <h2 style={{fontSize: "1.5rem", paddingBottom: "2vh", textAlign: "center", color: "#787ABD" }}>Progress</h2>
                                        <LazyLoad offsetBottom={600}> 
                                        <img src={Progress} width="250.8rem" height="469.68rem" alt="cocobotprogress"
                                        style={{display: "block", marginLeft:"auto", marginRight: "auto", marginBottom: "2vh"}}></img>
                                        </LazyLoad>
                                        <p style={{ marginBottom: "16vh", textAlign: "center" }} >
                                        This page allows caregivers to track their therapy milestones, progress, and symptoms. The clear data visualizations allow caregivers 
                                        to identify potential patterns about their wellbeing. 
                                        </p>
                                    </div>
                                    </Fade>
                                    </div>
                                    
                          

                                    
    
                            </Element>

                            <Fade bottom>
                            <LazyLoad offsetBottom={600}>  
                                <img src={divider} className="divider" width="400vw" alt="divider" ></img>
                            </LazyLoad>
                                <div className="extra-large-text-buddy" 
                                style={{ marginTop: "16vh" }}>
                                    The Process
                                </div>

                                <div className="extra-large-text" style={{ color: "#3E41A8" }}>
                                    Creating CocoBot (admist a Pandemic)
                                </div>
                                <LazyLoad offsetBottom={600}> 
                                <img src={divider} className="divider" width="400vw" alt="divider" 
                                style={{ marginTop:"16vh", marginBottom: "10vh", transform: "scaleX(-1)"}}></img>
                                </LazyLoad>
                            </Fade>

                            <Element className="research">
                                <Fade>

                                    <h1 style={{ color: "#3E41A8" }}>Research | Charging into Unfamiliar Territory</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginBottom: "5vh"}}>
                                        <p>
                                        Our team had limited knowledge about the healthcare space, such as the details of the Problem Solving Therapy approach. Given the time 
                                        constraints and the rich research our sponsor has conducted, we decided that conducting analysis on available resources would be the 
                                        most time-efficient and helpful method for helping us understand the design space.
                                        </p>
                                    </div>

                                    <div className="col" >
                                    <LazyLoad offsetBottom={600}> 
                                        <img src={research_1} width="100%" alt="miroboard"
                                        style={{ marginTop:"1vh", marginBottom: "8vh"}}></img>
                                    </LazyLoad>
                                        <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        Remote data synthesis using Miro
                                    </div>
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                        <p>
                                        From our research synthesis, we were able to understand the design space and identify appropriate solutions and design opportunities. 
                                        </p>
                                    </div>
                                    <div className="col">
                                       
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                                    <img src={research_2a} width="100%" alt="research findings"
                                        style={{ marginTop:"4vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        Main findings from our research
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                        <p>
                                        In particular, we successfully identified the gap that our mobile system aims to address. It stemmed from the absence
                                        of product that utilize Artificial Intelligence (AI)-based chatbot systems to address caregivers' needs, desires, and painpoints. <br/> <br/>

                                        From there, we were able to define the main requirements our mobile system should fulfill. 
                                        </p>
                                    </div>
                                    <div className="col">
                                        
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600}> 
                                    <img src={research_3} width="100%" alt="design requirements"
                                        style={{ marginTop:"12vh", marginBottom: "4vh", align: "center"}}></img>
                                    </LazyLoad>
                                   <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                        Design requirements generated from our research
                                    </div>

                                </Fade>
                            </Element>


                            <Element className="ideation">
                                <Fade>
                                <h1 style={{ color: "#3E41A8" }}>
                                Ideation | Creating a Blueprint
                                </h1>
                                <div className="row">
                                    <div className="col">
                                        <p>
                                        Upon reaching a consensus on our design recommendations and principles, it was time to map out a blueprint for our solution. 
                                        To ensure that all team members were on the same page and that the sponsors would be able to understand our envisioned product 
                                        clearly, we decided to first layout the skeleton of our product using a series of user flows. 
                                        </p>
                                    </div>
                                    <div className="col">
                                    </div>
                                </div>
                                <LazyLoad offsetBottom={600}> 
                                    <img src={ideation_1} width="100%" alt="user flows"
                                        style={{ marginTop:"1vh", marginBottom: "8vh"}}></img>
                                    </LazyLoad>
                                        <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Two key conversation flows that inspired the essential features of the application
                               
                                    </div>
                                </Fade>
                            </Element>

                            <Element className="wireframing">
                                <Fade>
                                <h1 style={{ color: "#3E41A8" }}>Wireframing | Turning Abstract Ideas into Concrete Designs</h1>
                                        <div style={{ borderLeft: "4px solid #787ABD" }}>
                                            <div className="emphasis-text-buddy">
                                                    Now came the first challenge that I was responsible for:
                                            </div>
                                            <div className="emphasis-text" style={{ color: "#3E41A8" }}>
                                            How do you lead a group in turning abstract ideas into a set of cohesive sketches while collaborating 
                                            remotely?
                                            </div>
                                        </div>

                                    <h2 style={{ color: "#787ABD" }}>My Solution (The 3Ds) </h2>
                                    </Fade>
                                    <Fade>
                                    <div className="row"> 
                                        <div className="col">
                                            <h3 style={{ color: "#3E41A8" }}>1. Discuss</h3>
                                            <p style={{ marginBottom: "6vh" }}>Since it was a mobile system, consistency was key — it was critical that everyone was on the same page. Hence, I led 
                                            the group in going over each feature and laid down the basics each feature should include.</p>
                                        </div>
                                        <div className="col" >
                                            <div className="col-image-wrap">
                                            <LazyLoad offsetBottom={600}>
                                                <img src={wireframing_1} width="100%" alt="initial general sketches"></img>
                                                </LazyLoad>
                                                <div className="pic-caption" style={{ marginBottom: "6vh"}}>
                                            
                                                My rough sketches for laying down the basics of the app
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>

                                    <Fade>
                                    <div className="row">
                                        <div className="col">
                                            <h3 style={{ color: "#3E41A8" }}>2. Divide</h3>
                                            <p style={{ marginBottom: "6vh"}}>Online collaboration can become exhausting and productivity drops drastically when people are tired. So, I divided up the work 
                                            by feature. At this stage, divergent sketches were welcomed. I was primarily responsible for sketching out the resources 
                                            and homepage features and the navigation bar.</p>  
                                        </div>

                                        <div className="col">
                                            <div className="col-image-wrap">
                                            <LazyLoad offsetBottom={600}>
                                                <img src={wireframing_2}  width="100%" alt="first iteration sketches"></img>
                                            </LazyLoad>
                                                <div className="pic-caption" style={{ marginBottom: "6vh"}}>
                                                    My first round of divergent sketches for the two features & nav bar
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    </Fade>

                                    <Fade>
                                    <div className="row">
                                        <div className="col">
                                            <h3 style={{ color: "#3E41A8" }}>3. Decide and Iterate</h3>
                                            <p style={{ marginBottom: "6vh"}}>This phase was repeated as we critiqued and discussed the sketches both internally and within the sponsor team. 
                                            After a few iterations, we successfully created a set of convergent sketches. <br/> <br/>
                                            Those sketches were then translated into mid-fidelity wireframes, which exhibited our ideas clearly and efficiently for the 
                                            next step of our process — usability testing. </p>
                                        </div>
                                        <div className="col">
                                            <div className="col-image-wrap">
                                            <LazyLoad offsetBottom={600}>
                                                <img src={wireframing_3}  width="100%" alt="final set of sketches"></img>
                                            </LazyLoad>
                                                <div className="pic-caption" style={{ marginBottom: "6vh"}}>
                                                The finalized set of convergent sketches
                                                </div>
                                            </div>
                                        </div>
                                    </div> 
                                    </Fade>
                             
                                
                            </Element>

                            <Element className="usability-testing">
                                <Fade>
                                <h1 style={{ color: "#3E41A8" }}>Usability Testing | Gathering User Feedback</h1>
                                <div style={{ borderLeft: "4px solid #787ABD" }}>
                                    <div className="emphasis-text-buddy">
                                        During this stage, I faced my second major challenge: 
                                    </div>
                                    <div className="emphasis-text" style={{ color: "#3E41A8" }}>
                                    How do you successfully plan for remote usability testing?
                                    </div>
                                </div>
                                <h2 style={{ color: "#787ABD" }}>My Solution</h2>
                                <div className="row">
                                        <div className="col">
                                <p>I came up with a detailed procedure and 
                                    timeline and appropriate types of data collection. For remote testing in particular, we also had to consider
                                     new factors such as how will the user utilize the prototype — thanks to my teammates' ideas, we adapted by
                                     having participants remote control the Figma prototype via their computer screens. </p>
                                    </div>
                                    <div className="col">
                                   
                                    </div>
                                </div>
                                <LazyLoad offsetBottom={600}>
                                <img src={testing_1} width="100%" alt="testing breakdown"
                                        style={{ marginTop:"12vh", marginBottom: "4vh"}}></img>
                                </LazyLoad>
                                <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                Breakdown of our usability testing sessions
                                </div>
                                <h2 style={{ color: "#787ABD" }}>What Did We Learn from the Users?</h2>
                                <LazyLoad offsetBottom={600}>
                                <img src={testing_2} width="100%" alt="main findings"
                                        style={{ marginTop:"4vh", marginBottom: "4vh"}}></img>
                                </LazyLoad>
                                <div className="pic-caption" style={{ marginBottom: "12vh", textAlign: "center"}}>
                                Main findings based on our qualitative and quantitative analysis
                                </div>
                                <p> For details about our usability testing, please see our usability testing report.</p>
                                <div style={{ marginBottom: "12vh", position: "relative", marginTop: "0" }}>
                                    <a href="https://drive.google.com/file/d/1_RoZNfUPw7E3vZm8PYp5cE5_uS3p38t2/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                                      
                                    <button className="button" style={{ marginBottom: "12vh"}}> Usability Testing Report </button></a>
                                        </div>
                                </Fade>
                            </Element>

                            <Element className="iteration">
                                <Fade>
                                <h1 style={{ color: "#3E41A8" }}>Iteration | Refining Based on User Feedback</h1>
                                <div className="row">
                                        <div className="col">
                                <p>The users’ helpful feedback gave us insights into ways we can adjust and refine our designs. 
                                    Through translating their feedback into actionable design recommendations, I was able to 
                                    appropriately adjust and refine the Today and Progress sections. <br/> <br/>
                                    We also create a visual design system to ensure consistency across our final designs.</p>
                                    </div>
                                    <div className="col">
                                    <div className="col-image-wrap" style={{ marginTop:"0", paddingTop: "0"}}>
                                    <LazyLoad offsetBottom={600}>
                                    <img src={iteration_1} width="100%" alt="design recommendation example"
                                        style={{ marginTop:"1vh", marginBottom: "4vh"}}></img>
                                    </LazyLoad>
                                <div className="pic-caption" style={{ marginBottom: "12vh" }}>
                                A snippet of some design recommendations (in white) derived from user feedback
                                </div>
                                </div>
                                    </div>
                                </div>
                                <h2 style={{ color: "#787ABD" }}>Iteration on Today</h2>
                                <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                <p>The main concern with the Today page wireframe was the misleading affordances. Some users 
                                    were confused as to whether or not they can click on the sections directly as they were 
                                    shaped as cards. To eliminate this potential confusion, the final design eliminated the 
                                    buttons so the affordance is clear.</p>
                                    </div>
                                    <div className="col">
                                        
                                        <div className="col-image-wrap" style={{ marginTop:"0", paddingTop: "0"}}>
                                        <LazyLoad offsetBottom={600}>
                                    <BeforeAfterSlider alt="today before after"
                                        before={before_1}
                                        after={after_1}
                                        width={282.15}
                                        height={564.3}
                                    />
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Hover over to see the before and after of the Today page
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                <h2 style={{ color: "#787ABD" }}>Iteration on Progress</h2>      
                                <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                <p>It was observed that the wireframe for the progress page a bit difficult to understand due to ineffective
                                    data display. 
                                    To ensure the final design displays data effectively, 
                                    I conducted an interview with an expert in the data visualization field. The final design I came up with 
                                    compartmentalized the different progress elements and established a hierarchy for the information presented. </p>
                                    </div>
                                    <div className="col">
                                        
                                        <div className="col-image-wrap" style={{ marginTop:"0", paddingTop: "0"}}>
                                        <LazyLoad offsetBottom={600}>
                                    <BeforeAfterSlider alt="today before after"
                                        before={before_2}
                                        after={after_2}
                                        width={282.15}
                                        height={564.3}
                                    />
                                    </LazyLoad>
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Hover over to see the before and after of the Progress page
                                    </div>
                                    </div>
                                    </div>
                                    </div>
                                </Fade>
                            </Element>


                            <Element className="outcome">
                                
                            <Fade bottom>
                                <img src={divider} className="divider" width="400vw" alt="divider" ></img>

                                <div className="extra-large-text-buddy" 
                                style={{ marginTop: "16vh" }}>
                                    The Outcome
                                </div>

                                <div className="extra-large-text" style={{ color: "#3E41A8" }}>
                                Six Months of Hard Work = <span role="img" aria-label="Celebrate" style={{ fontSize: "inherit" }}>🎉</span>
                                </div>

                                <img src={divider}className="divider" width="400vw" alt="divider" 
                                style={{ marginTop:"16vh", marginBottom: "10vh", transform: "scaleX(-1)"}}></img>
                            </Fade>
                                <Fade> 
                                    
                                    <div className="section-wrap">
                                    <h2 style={{ marginTop: "0", color: "#787ABD"}}>
                                    1. User-approved
                                    </h2>
                                    <p>
                                    When asked about their experiences with task completion, the mode for participants' ratings across tasks was
                                    5/5.
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#787ABD"}}>
                                    2. Sponsor-approved
                                    </h2>
                                    <p>
                                    The sponsors were extremely pleased with the high-quality work we produced and extended an invitation to continue helping them. 
                                    The developers are currently coding the platform based on the designs and interactions we delivered.
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#787ABD" }}>
                                    3. Department and Industry-approved
                                    </h2>
                                    <p style={{ marginBottom: "4vh"}}>
                                    Our project was <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #787ABD 20%)" }}
                                href="https://www.instagram.com/p/CB1UWiMgAyN/"
                                target="_blank" rel="noopener noreferrer">featured first on HCDE’s Instagram</a>! From our final project 
                                    showcase, we also learnt that industry professionals and leaders and the HCDE faculty were all very impressed by the work we produced.
                                    </p>
                                    <h2 style={{ marginTop: "0", color: "#787ABD", marginBottom: "4vh"}}>
                                    Check out our visual process book! It provides more details about the holistic process!
                                    </h2>
                                
                                    <div>
                  
                                    </div>
                                   
                                    
                                    </div>
                                    <div>
                                    <iframe title="cocobot_processbook" style={{border: "1px solid rgba(0, 0, 0, 0.1)", width:"100%", height:"60rem"}}
                                     src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2FVZfBp3DYVAuZapwXxmjI1Q%2F493_ProcessBook%3Fnode-id%3D257%253A1106%26scaling%3Dcontain" allowFullScreen></iframe>
                                        
                                    </div>
             

                                    
                                
                                    </Fade>
                            </Element>



                            <Element className="reflection">
                                <Fade>  
                                    <h1 style={{ color: "#3E41A8" }}>Reflection</h1>
                                    <div className="row">
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}>
                                        <img src={reflection_1} width="85%" alt="cocobotteam"></img>
                                    </LazyLoad>
                                        <div className="pic-caption" style={{ marginBottom: "4vh"}}>
                                            The lovely CocoBot team 
                                        </div>
                                    </div>

                                    <div className="col" style={{ marginBottom: "8vh"}}>

                                    <h2 style={{ marginTop: "0", color: "#787ABD"}}>
                                    1. Effective Communication is Key
                                    </h2>
                                    <p>
                                    As the sponsor liaison, 
                                    I learnt the importance of crafting the presentation material toward the stakeholders involved and using the appropriate 
                                    language so our ideas come across clearly. 
                                    </p>
                                    <h2 style={{ marginTop: "4vh", color: "#787ABD"}}>
                                    2. “Be like water”
                                    </h2>
                                    <p>
                                    I learnt to adapt swiftly and creatively to problems. Changes in plans are okay and they 
                                    serve as valuable learning opportunities.
                                    </p>

                                    <h2 style={{ marginTop: "4vh", color: "#787ABD" }}>
                                    3. Accessibility/dark mode considerations
                                    </h2>
                                    <p style={{ marginBottom: "4vh" }}>
                                    If more time was given, I would like to scrutinize the accessibility aspect of our final design, such as double
                                    checking on the colour contrast ratio for all colours. In addition, 
                                    I would delve deeper into 
                                    the construction of a dark mode, to better support users who might use our platform at night-time. 
                                    </p>

                                 
                                    </div>
                                    </div>
                                    </Fade>
                            </Element>
                     
                            <footer>
                                <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}> Next project~
                                    </h1><br/>
                                    <div className="footer-wrap">
                                    <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #F47A20 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw"}}
                                href="/yirental"
                                 rel="noopener noreferrer">Yirental</a> 
                                    
                               
                        </div>
                        </div>
                        </Fade>
                        </footer>
                        <Fade bottom> 
                        <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com"  style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/"  style={{backgroundImage: "linear-gradient( white 80%, #787ABD 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Purple Heart" style={{ fontSize: "inherit" }}>💜</span> 
                        </div>
                        </Fade>
                        </div>
                    </Fade>
                </div>
            </div>
        );
    }
};

render(<Cocobot/>, document.getElementById('root'));

export default Cocobot

/*    <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #98b5EF 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/naturefinder"
                                rel="noopener noreferrer">Nature Finder</a>     
                                 
                                 
   <h2 style={{ color: "#787ABD" }}>
                                    stay tuned for updates! <span role="img" aria-label="Radio" style={{ fontSize: "inherit" }}>📻</span>
                                    </h2>    
<br/> <br/> 
                                     For a detailed usability testing report, please click  <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit"}}
                                href="https://drive.google.com/drive/u/1/folders/1Zr1PQpXoKdGOziGdCKrcla8E09MJRSDw" 
                                target="_blank" rel="noopener noreferrer">here</a>.
<a href="https://drive.google.com/file/d/1yWQCY5WvEKHXZyCPe41aMbKrXdWLRQbR/view?usp=sharing" target="_blank" rel="noopener noreferrer">
                                        <button className="button" style={{ marginBottom: "4%" }}> Link to PDF </button>
                                    </a> */
/*     <h2 style={{ marginTop: "4vh", color: "#787ABD" }}>
                                    Play Around with the CocoBot Interactive Protoype!~
                                    </h2>
                                    <div>
                                    <iframe style="border: 1px solid rgba(0, 0, 0, 0.1);" width="800" height="450" src="https://www.figma.com/embed?embed_host=share&url=https%3A%2F%2Fwww.figma.com%2Fproto%2F5U23GvPw9tkBA4MSpqo9yl%2F493_CocoBotHifi%3Fnode-id%3D690%253A639%26scaling%3Dscale-down" allowfullscreen></iframe>
                                    </div>
*/

/*<div className="col">
                                    <div className="col-image-wrap" style={{ marginTop: "1vh", paddingTop: "0"}}>
                                        <img src={outcome_1} className="outcome_1" width="100%" alt="hcdeins"></img>
                                        <div className="pic-caption" style={{ marginBottom: "2vh"}}>
                                            Post on HCDE's Instagram page
                                        </div>
                                    </div>
                                    </div>
*/

/*<img src='.divider.png' className="divider" width="400vw" alt="divider"></img>
                                    <div className="extra-large-text-buddy" 
                                    style={{ marginTop: "16vh" }}>
                                        The Outcome
                                    </div>

                                    <div className="extra-large-text">
                                        6 months of work = ?
                                    </div>
                                                                
                                    <img src='.divider.png' className="divider" width="400vw" alt="divider" 
                                    style={{ marginTop:"16vh", marginBottom: "10vh", transform: "scaleX(-1)"}}></img>
                                    */
/*<li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #3E41A8 20%)"}} className="Section 1" to="section1" spy={true} smooth={true} duration={500}>Final Product</Link></li> */
/*                            <Fade>

                            <Element name="section1" className="element">
                                <p>
                                words 
                                </p>
                            </Element>
                            </Fade> */

/*<div>
                                        <img src={onboarding} width="220rem" height="412rem" alt="cocobotonboarding" 
                                        ></img>
                                    </div>
                                    <div>
                                        <h2>Onboarding</h2>
                                        <p>
                                        Introduction carousel allows effective understanding of app's funcationalities <br/> <br/>
                                        Interactive guiding components primes for chatbot interactions
                                        </p>
                                    </div>




                                    
                                    <div className="col-6" style={{ textAlign: "right", paddingLeft: "6vw" }}>
                                        <h2>Today</h2>
                                        <p> 
                                        Clickable card reminds user of upcoming sessions <br/> <br/>
                                        Handy to-do list helps track progress and crossing-out items sparks a moment of bliss <br/> <br/>
                                        Quick access to relevant resources
                                        </p>
                                    </div>
                                    <div className="col-6" style={{ height: "452px", marginBottom: "6vh"}}>
                                        <img src={today} width="220vw" height="412vh" alt="cocobottoday" 
                                        style={{ marginLeft: "5vw", marginTop: "2vh"}}></img>
                                    </div>

                                    <div className="col-6">
                                        <img src={chat} width="220vw" height="412vh" alt="cocobotchat" 
                                        style={{ marginLeft: "12vw", marginTop: "2vh"}}></img>
                                    </div>
                                    <div className="col-6" style={{ height: "452px", marginBottom: "6vh", paddingRight: "6vw" }}>
                                        <h2>Chat</h2>
                                        <p>
                                        Interactive chat components break down potentially long conversations <br/> <br/>
                                        Considerate features elevate the chat experience
                                        </p>
                                    </div>

                                    <div className="col-6" style={{ textAlign: "right", paddingLeft: "6vw"}}>
                                        <h2>Resources</h2>
                                        <p>
                                        SOS button provides credible emergency resources <br/> <br/> 
                                        Categorization, tagging, search, and filtering allow for efficient resource access
                                        </p>
                                    </div>
                                    <div className="col-6" style={{ marginBottom: "6vh" }}>
                                        <img src={resource} width="220vw" height="412vh" alt="cocobotresource" 
                                        style={{ marginLeft: "5vw", marginTop: "2vh"}}></img>
                                    </div>

                                    <div className="col-6">
                                        <img src={progress} width="220vw" height="412vh" alt="cocobotprogress" 
                                        style={{ marginLeft: "12vw", marginTop: "2vh"}}></img>
                                    </div>
                                    <div className="col-6" style={{ paddingRight: "6vw", height: "452px", marginBottom: "6vh" }}>
                                        <h2>Progress</h2>
                                        <p>
                                        Highlighted streak increases motivation <br/> <br/>
                                        Stats and data visualization track progress and convey potential patterns about emotional and physical
                                        states <br/><br/>
                                        Clear and descriptive data effectively illustrates progress and the PST therapy approach
                                        </p>
                                    </div>
*/