import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './starbucks.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import overview_1 from './overview_1.jpg'
import interviews_1 from './interviews_1.png'
import interviews_2 from './interviews_2.jpg'
import interviews_3 from './interviews_3.jpg'
import interviews_4 from './interviews_4.jpg'
import fieldstudies_1 from './fieldstudies_1.png'
import fieldstudies_2 from './fieldstudies_2.jpg'
import fieldstudies_3 from './fieldstudies_3.jpg'
import surveys_1 from './surveys_1.png'
import surveys_2 from './surveys_2.png'
import surveys_3 from './surveys_3.png'
import surveys_4 from './surveys_4.png'
import surveys_5 from './surveys_5.png'
import surveys_6 from './surveys_6.png'
import recommendations_1 from './recommendations_1.png'
import recommendations_2 from './recommendations_2.png'
import recommendations_3 from './recommendations_3.png'

/*light 97C29D */
/*036635*/


class Starbucks extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #97C29D 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="starbucks-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Field Studies" to="field studies" spy={true} smooth={true} duration={500}>Field Studies</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Interviews" to="interviews" spy={true} smooth={true} duration={500}>Interviews</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Surveys" to="surveys" spy={true} smooth={true} duration={500}>Surveys</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Recommendations" to="recommendations" spy={true} smooth={true} duration={500}>Recommendations</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} className="Reflection" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Green Heart" style={{ fontSize: "inherit" }}>💚</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#036635"}}>
                        Starbucks: Waiting Line Study<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        UX Research
                        </div> 
 
                        <Element className="overview">
                                <Fade bottom>
                                <h1 style={{ color: "#036635"}}>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> The Starbucks waiting lines on the Unviersity of Washington (UW) campus have been causing unnecessary frustration and annoyance for UW students. 
                                        So, I decided to embark on a journey to find ways to improve the waiting line experience at the UW Starbucks for 
                                        undergraduate students. The research composed of three components: field studies, semi-structured interviews, 
                                        and surveys and was guided by the design question of: How might we make the waiting time of the UW Starbucks’ 
                                        lines more efficient for UW’s undergraduates? 
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#97C29D "}}>Role</h2>
                                        <p>UX Researcher</p>
                                        <h2 style={{ color: "#97C29D " }}>Duration</h2>
                                        <p>Mar to Jun 2019 (3 months)</p>
                                        <h2 style={{ color: "#97C29D" }}>Tools</h2>
                                        <p>Google Suite | Miro | Microsoft Excel</p>
                                    </div>
                                    </div>
                                    <div className="extra-large-text-buddy" style={{ marginTop: "8vh" }}>
                                        Before we start, let me describe
                                    </div>

                                    <div className="extra-large-text" style={{marginBottom: "10vh", color: "#036635" }}>
                                        The Design Space
                                    </div>
                                 
                                    <div className="row">
                                        <div className="col">
                                    <LazyLoad offsetBottom={100}>
                                        <div className="image-wrap">
                                            <img src={overview_1} width="100%" alt="the design space"
                                            style={{ marginBottom: "4vh"}}></img>
                                            </div>
                                            </LazyLoad>
                                            
                                            </div>
                                            
                                            <div className="col" style={{ marginBottom: "16vh"}}>
                                            <p style={{ paddingBottom: "6vh"}} >
                                            Out of the two Starbucks at the UW, the Suzzallo Library's Starbucks was chosen 
                                            for its centralized location and high population flow. It is located at the heart of where most students
                                            pass-by on a daily basis and features a comprehensive menu of drinks, snacks, and meals.
                                    </p>
                                    </div>
                                    </div>
                                   
                                </Fade>
                        </Element>

                        <Element className="field studies">
                                <Fade>
                                <h1 style={{ color: "#036635"}}>Field Studies</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> I began the exploration through engaging in three-hour-long structured observations at varying times at 
                                        the design space of choice. Structured observations gave the opportunity to collect qualitative data efficiently 
                                        and permits the extraction of behavioural and interaction patterns through data analysis. <br/> <br/>
                                        The key individuals observed
                                         are undergraduate students between 16 to 24 years of age. The cohort makes up the majority of UW’s student body, 
                                         are ones most likely to have regular timed schedules, and are the most frequent cohort encountered at Starbucks. 
                                         Upon coding the data, I extracted meaningful sections to create an affinity diagram that allowed me to perceive 
                                         the underlying themes.

 
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                    <LazyLoad offsetBottom={100} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={fieldstudies_1} width="100%" alt="snippets of coded data"></img>
                                    </LazyLoad>
                                    <LazyLoad offsetBottom={100} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={fieldstudies_2} width="100%" alt="affinity mapping"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    A snippet of the data I coded based on the observations; affinity diagram
                                    </div>
                                    </div>
                                    </div>
                                   
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> Through creating an affinity diagram, I identified the following key themes:

 
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={fieldstudies_3} width="100%" alt="top three field study findings"></img>
                                    </LazyLoad>
                                   
                                     
                                     
            
                                    </div>
                                    </div>
                                    
                                    
                                </Fade>
                        </Element>
                        <Element className="interviews">
                                <Fade>
                                <h1 style={{ color: "#036635"}}>Semi-structured Interviews</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> One of the key weaknesses of the field study was not being able to understand the why behind users’ actions. 
                                        I went on to conduct three 30 minutes long semi-structured interviews with users to validate and elaborate on 
                                        the rationale behind the previous findings.
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "16vh"}}>
                                    
                                    </div>
                                    </div>
                                    <div style={{ borderLeft: "4px solid #036635", marginBottom: "4vh"}}>
                                            <div className="emphasis-text-buddy" style={{ color: "#242424"}}>
                                            The interviews were driven by the revised design question of:
                                            </div>
                                            <div className="emphasis-text" style={{ color: "#036635"}}>
                                            How might we improve the efficiency of the UW Starbucks waiting
                                             line time through understanding the rationale of students’ behaviours and interactions?
                                            </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={interviews_1} width="100%" alt="coded data"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                   Coding the interview data
                                    </div>
                                    
                                    </div>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={interviews_2} width="100%" alt="affinity analysis"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Affinity analysis
                                    </div>
                                    </div>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={interviews_3} width="100%" alt="thematic mapping"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Thematic mapping
                                    </div>
                                    
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> Based on the interviews, I extracted the following findings:

 
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={interviews_4} width="100%" alt="top three interview findings"></img>
                                    </LazyLoad>
                                   
                                     
                                     
            
                                    </div>
                                    </div>
                                </Fade>
                        </Element>
                        <Element className="surveys">
                                <Fade>
                                <h1 style={{ color: "#036635"}}>Surveys</h1>
                                    <div className="row" style={{  marginBottom: "8vh"}}>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> Upon identifying intriguing patterns via the two methods, I re-crafted my design 
                                        question again to focus on the improvement of social and environmental factors to reflect those findings 
                                        for surveys: 
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" >
                                    
                                    </div>
                                    </div>
                                    <div style={{ borderLeft: "4px solid #036635", marginBottom: "4vh"}}>
                                            
                                            <div className="emphasis-text" style={{ color: "#036635"}}>
                                            How might we improve the social and environmental factors at the UW Suzzallo 
                                            Starbucks to ameliorate UW Undergraduate students' waiting line experiences?
                                            </div>
                                </div>

                                <div className="row">
                                    <div className="col" style={{ marginBottom: "12vh"}}>
                                    <p>The survey allowed the generalizability of my interview findings, which had a limited sample size. 18 total 
                                        responses were collected via Google Forms.
                                         Upon gathering data and analyzing them through Microsoft Excel, the following findings were extracted: 
                                         </p>
                                    </div>
                                    <div className="col" >

                                    </div>
                                </div>
                                <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>1. Quick In, Quick Out</h2>
                                    <p>Students value efficiency of Starbucks experience in terms of time, many go there during breaks between classes
                                         </p>
                                    </div>
                                    <div className="col">
                                   
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_1} width="100%" alt="dissastifaction chart"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col" >
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_2} width="100%" alt="rationales behind visiting timese"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                </div>

                                
                                


                                <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>2. High Prevalence of Smartphone Usage</h2>
                                    <p>87.5% of participants selected the smartphone as the most frequently interacted object while waiting in line; 
                                        students associate the waiting line experience with boredom and they use smartphones as a way to combat it
                                         </p>
                                    </div>
                                    <div className="col">
                                   
                                    </div>
                                </div>
                                
                                <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_3} width="90%" alt="most frequent object chart"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col" >
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_4} width="100%" alt="rationales behind most frequent object chart"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                </div>


                                <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>3. Students’ Most Likely Visit Times</h2>
                                    <p>Myajority of students visit Starbucks when it is nearby for them, which is usually during the afternoon (1:30 PM - 4:30 PM)
                                         </p>
                                    </div>
                                    <div className="col">
                                   
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col" style={{ marginBottom: "4vh"}}>
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_5} width="100%" alt="reason for visit chart"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col" >
                                        <div className="image-wrap">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginBottom: "4vh"}} src={surveys_6} width="100%" alt="times of visit chart"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                </div>
                      
                                    
                                </Fade>
                        </Element>
                        <Element className="recommendations">
                                <Fade>
                                <h1 style={{ color: "#036635"}}>Recommendations</h1>
                                    <div className="row" style={{ marginBottom: "12vh"}}>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> From the three research methods, I believe the following are the most optimal solutions to 
                                        improve the waiting line experience for UW undergraduates at the UW Starbucks. They are prioritized based on their abilities
                                        to address issues found effectively.
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" >
                                        
                                    </div>
                                    </div>
                                    
                                    <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>1. Adopt an Automatic Ordering Kiosk </h2>
                                    <p>The kiosk will help reduce both the congestion during peak times by allowing workers to focus on prepping the dirnks as well
                                        as exhibit menu choices to improve the unclear and non-comprehensive physical menu.
                                         </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={recommendations_1} width="100%" alt="automatic kiosk"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Conceptual sketch of the kiosk
                                    </div>
                                    </div>
                                </div>
                                    

                                <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>2. Enable Mobile Ordering </h2>
                                    <p>This recommendation provides a potentially faster alternative to the previous recommendation for addressing waiting line cognestions. While mobile ordering 
                                        is offered for off-campus Starbucks,
                                         it is not offered for the Suzzallo Starbucks. 
                                         </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={recommendations_2} width="100%" alt="mobile ordering"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Conceptual sketch of the mobile order experience
                                    </div>
                                    </div>
                                </div>


                                <div className="row">
                                <div className="col" style={{ marginBottom: "8vh"}}>
                                <h2 style={{ color: "#97C29D"}}>3. Digital Bulletin </h2>
                                    <p>Placing a digital bulletin with real-time estimated wait time outside of Suzzallo library allows students to be cognizant of the wait time 
                                        prior to entering the Starbucks. 
                                         </p>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={recommendations_3} width="100%" alt="digital bulletin"></img>
                                    </LazyLoad>
                                     
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                    Conceptual sketch of the digital bulletin
                                    </div>
                                    </div>
                                </div>

                                </Fade>
                        </Element>
                        <Element className="reflection">
                                <Fade>
                                <h1 style={{ color: "#036635"}}>Reflection</h1>
                                    <div className="row" style={{ marginBottom: "18vh"}}>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> As my exploration at the Suzzallo Starbucks came to an end, I: <br/> <br/>

                                    1. Developed a profound respect for UX researchers; it requires extremely strong analytical skills and the ability and patience to scrutinize data <br/> <br/>
                                    2. Comprehended the weaknesses and strengths of each method and how certain methods may fit better into certain situations <br/> <br/>
                                    3. Realized that research is fallible to biases, and it is the researchers' careful considerations that can help combat against those biases <br/> <br/>
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        
                                    </div>
                                    </div>
                                    
                                </Fade>
                        </Element>
                        <footer>
                            <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~</h1><br/>
                                    <div className="footer-wrap">
                                    <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #B3A9D8 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/uwlabs"
                               rel="noopener noreferrer">Explorations in HCI Labs</a> 
                                
                                </div>
                                </div> 
                                </Fade>
                    
            </footer>
                    </div>
                    <Fade bottom>
                    
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #97C29D 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Green Heart" style={{ fontSize: "inherit" }}>💚</span>
                    </div>
                    
            
                </Fade>
                </Fade>
            </div>
            </div>
        );
    }
};

render(<Starbucks/>, document.getElementById('root'));

export default Starbucks

/*      <a 
                                className="hyperlink-text"style={{  fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)", color: "#242424", marginBottom: "1vh", marginRight: "2vw" }}
                                href="/parkaid"
                               rel="noopener noreferrer">Parkaid</a> 
                               */