import React from 'react';
import { render } from 'react-dom';
import { Link, Element, Events, animateScroll as scroll, scroller } from 'react-scroll'
import '../../App.css'
import './parkaid.css'
import Fade from 'react-reveal/Fade'
import { FiChevronLeft, FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"
import LazyLoad from 'react-lazy-load'
import discover_1 from './discover_1.png'
import ideation_1 from './ideation_1.png'
import design_1 from './design_1.png'
import design_2 from './design_2.png'
import design_3 from './design_3.jpg'
import parkaid_1 from './parkaid_1.gif'
import parkaid_2 from './parkaid_2.gif'
import parkaid_3 from './parkaid_3.gif'
/*BADCF4*/
/*F1C900*/
/*FFD601 new yellow*/


class Parkaid extends React.Component {

    constructor(props) {
        super(props);
        this.scrollToTop = this.scrollToTop.bind(this);
    }

    componentDidMount() {
        Events.scrollEvent.register('begin', function () {
        console.log("begin", arguments);
        });
        Events.scrollEvent.register('end', function () {
        console.log("end", arguments);
        });
    }

    scrollToTop() {
        scroll.scrollToTop();
    }

    scrollTo() {
        scroller.scrollTo('scroll-to-element', {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuart'
        })
    }

    scrollToWithContainer() {
        let goToContainer = new Promise((resolve, reject) => {
            Events.scrollEvent.register('end', () => {
                resolve();
                Events.scrollEvent.remove('end');
            });
            scroller.scrollTo('scroll-container', {
                duration: 800,
                delay: 0,
                smooth: 'easeInOutQuart'
            });
        });

        goToContainer.then(() =>
        scroller.scrollTo('scroll-container-second-element', {
            duration: 800,
            delay: 0,
            smooth: 'easeInOutQuart',
            containerId: 'scroll-container'
            }));
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
    }

    render() {
        return (
            <div>
               
                    <header className="col-2">
                    <Fade left>
                        <div className="project-navbar">
                            <IconContext.Provider value={{ color: "242424", size: "2.8rem"}}>
                                <div >
                                    <a className="backbutton" style={{ backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)" }} href="/" rel="noopener noreferrer">
                                        <FiChevronLeft/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <ul className="parkaid-nav-sections">
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} className="Overview" to="overview" spy={true} smooth={true} duration={500}>Overview</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} className="Discover" to="discover" spy={true} smooth={true} duration={500}>Discover</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} className="Ideation" to="ideation" spy={true} smooth={true} duration={500}>Ideation</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} className="Design" to="design" spy={true} smooth={true} duration={500}>Design</Link></li>
                                <li><Link activeClass="active" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} className="Reflection" to="reflection" spy={true} smooth={true} duration={500}>Reflection</Link></li>
                            </ul>

                            <IconContext.Provider value={{ color: "242424", size: "1.4rem"}}>
                                <div className="social-icons">
                                    <a id="email-icon" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                                        <FiMail/>
                                    </a>
                                    <a href="https://www.linkedin.com/in/zhuamanda/"style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} target="_blank" rel="noopener noreferrer">
                                        <FiLinkedin/>
                                    </a>
                                </div>
                            </IconContext.Provider>

                            <div className="credits">
                            © Amanda Zhu 2024 <br />
                            Created with React + <span role="img" aria-label="Blue Heart" style={{ fontSize: "inherit" }}>💙</span>
                            </div>
                        </div>
                        </Fade>
                    </header>
           

                <div className="col-10">
                <Fade bottom>
                    <div className="content-wrap">
                        <div className="projectpage-title" style={{ color: "#595959"}}>
                        Parkaid: Parking Re-imagined<br/> 
                        </div> 
                        <div className="projectpage-type" >
                        Interaction Design
                        </div> 

                        <Element className="overview">
                                <Fade bottom>
                                <h1 style={{ color: "#595959"}}>Overview</h1>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> The car industry strives to incorporate innovative designs. However, some aspects have been neglected. 
                                        Amongst those experiences is the parking experience. I created this project to uncover a fitting solution
                                         to make the parking experience more efficient for drivers.
                                    </p> <br/>
                                    
                                    </div>
                                  
                                    <div className="col" style={{  marginBottom: "24vh"}}>
                                        <h2 style={{ marginTop: "0",  color: "#BADCF4"}}>Role</h2>
                                        <p>Interaction Designer</p>
                                        <h2 style={{ color: "#BADCF4" }}>Duration</h2>
                                        <p>July 2019 (2 weeks)</p>
                                        <h2 style={{ color: "#BADCF4" }}>Tools</h2>
                                        <p>Windows Ink | Figma | Adobe Photoshop</p>
                                    </div>
                                    </div>
                                    
                                    
                                </Fade>
                        </Element>
                        <Element className="discover">
                                <Fade>
                                <h1 style={{ color: "#595959"}}>Discover</h1>
                                <div style={{ borderLeft: "4px solid #595959" }}>
                                            <div className="emphasis-text" style={{ color: "#595959"}}>
                                                   Parking is a huge problem.
                                            </div>
                                </div>
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> To understand this problem in more detail, I began an online exploration through articles and statistics as well as conversing with those around me. During this process, 
                                        I discovered <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)" }}
                                href="https://inrix.com/research/"
                                target="_blank" rel="noopener noreferrer">INRIX (2017)</a>’s study on parking frustrations that had a robust set of data from over 100 000 parking lots in 8 700 
                                        cities across 10 countries and survey results from 17 968 drivers in 30 large cities from the U.S., U.K., and Germany. It 
                                        demonstrated that the key issue drivers face is related to searching for parking spots.
                                    </p> <br/>         
                                    </div>
                                    <div className="col" >
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={100} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={discover_1} width="85%" alt="research findings"></img>
                                    </LazyLoad>
                                       
                                        <div style={{ borderLeft: "4px solid #595959" }}>
                                            <div className="emphasis-text-buddy" style={{ color: "#242424"}}>
                                            This led to my design question of:
                                            </div>
                                            <div className="emphasis-text" style={{ color: "#595959"}}>
                                            How do I design an integrated platform that allows a more efficient 
                                            and hassle-free parking experience for drivers?
                                            </div>
                                </div>
                                </Fade>
                        </Element>
                        <Element className="ideation">
                                <Fade>
                                <h1 style={{ color: "#595959"}}>Ideation</h1>
                                
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> Upon identifying the key issues, I brainstormed solutions, converged and selected the best one, and created a storyboard to contextualize it. <br/> <br/>
                                        The storyboard delineates a mobile platform to allow drivers to book parking spots ahead of time. They can use the platform to navigate to their 
                                        parking spots and use <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)" }}
                                href="https://en.wikipedia.org/wiki/Near-field_communication"
                                target="_blank" rel="noopener noreferrer">Near Field Communication (NFC)</a> to check-in and check-out of their parking spot. This will reduce
                                         the time they waste trying to locate parking spots.
                                        
                                       
                                    </p>        
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={ideation_1} width="85%" alt="storyboard"></img>
                                    </LazyLoad>
                                     
                                    <div className="pic-caption" style={{ marginBottom: "12vh"}}>
                                        Storyboard conceptualizing my idea
                                    </div>
                              
                                </Fade>
                        </Element>
                        <Element className="design">
                                <Fade >
                                <h1 style={{ color: "#595959"}}>Design</h1>
                                
                                    <div className="row">
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "12vh"}}>
                                    <p> The storyboard helped create an initial interaction flow. Upon receiving critique on the interactions and layouts of the design, 
                                        I revised the design to create Parkaid: a mobile application that integrates mobile phones’ connectivity and unique
                                         identifier to create more efficient and hassle-free interactions for parking.
                                        
                                    </p> <br/>         
                                    </div>
                                    <div className="col" >
                                    </div>
                                    </div>
                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#BADCF4" }}>1. Booking the parking spot</h2>
                                        <p>This allows drivers to book from the comfort of their home, or office, or wherever they are. 
                                            Just simply search, select, and pay.</p>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={design_1} width="85%" alt="booking the spot"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}>
                                            <img src={parkaid_1} width="250.8rem" height="469.68rem" alt="booking the spot UI"
                                            style={{marginBottom: "12vh"}}></img>
                                            </LazyLoad>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#BADCF4"}}>2. Navigating to + from spot</h2>
                                        <p>This feature guides drivers to their parking spot. Forgot where one parked? 
                                            Just use the navigation feature to get there.</p>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={design_2} width="85%" alt="navigating to and from the spot"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}>
                                            <img src={parkaid_2} width="250.8rem" height="469.68rem" alt="navigating to and from UI"
                                            style={{marginBottom: "12vh"}}></img>
                                            </LazyLoad>
                                    </div>
                                    </div>

                                    <div className="row">
                                    <div className="col">
                                   <div className="image-wrap">
                                    <h2 style={{ color: "#BADCF4"}}>3. Checking in + out</h2>
                                        <p>The NFC sticker on the parking beam enables drivers to tap their mobile devices to check-in. The timer will begin as soon as the driver confirms.
                                             Similar process applies when checking-out, confirming alerts the system that the parking spot is now open.</p>
                                    <LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "12vh"}} src={design_3} width="85%" alt="checking in + out"></img>
                                    </LazyLoad>
                                    </div>
                                    </div>
                                    <div className="col">
                                    <LazyLoad offsetBottom={600}>
                                            <img src={parkaid_3} width="250.8rem" height="469.68rem" alt="checking in + out UI"
                                            style={{marginBottom: "4vh"}}></img>
                                            </LazyLoad>
                                    </div>
                                    </div>
                                     
                                        
                              
                                </Fade>
                        </Element>
                        <Element className="reflection">
                                <Fade >
                                <h1 style={{ color: "#595959"}}>Reflection</h1>
                                
                                    <div className="row" style={{ marginBottom: "24vh"}}>
                                    <div className="col" style={{ marginRight: "4vw", paddingBottom: "3vh"}}>
                                    <p> The project taught me to be more observant about the daily, seemingly mundane interactions, 
                                        that occur all around us. It provoked thoughts about how we are able to refine interactions 
                                        through designing with care, from parking to opening doors. If time permits in the future,
                                         I want to address some shortcomings of the current project. For one, the current concept 
                                         caters toward parking lots. In reality, street parking is also really popular. I want to 
                                         integrate a good way for street parking as well. Second, I would like to test the idea with
                                          drivers, to see if the app would actually address their needs.


                                       
                                    </p>        
                                    </div>
                                    <div className="col">
                                    </div>
                                    </div>
                                  
                              
                                </Fade>
                        </Element>
                        <footer>
                            <Fade bottom>
                      <div style={{ marginBottom: "10vh"}}>
                                    <h1 style={{ color: "#242424"}}>Next project~
                                    </h1><br/>
                                    <div className="footer-wrap">
                                    <a
                                style={{ fontSize: "inherit", color: "inherit",  backgroundImage: "linear-gradient( white 80%, #E62790 20%)", marginBottom: "1vh", marginRight: "2vw"}}
                                href="/usafacts"
                                rel="noopener noreferrer">USAFacts</a> 
                                
                                </div>
                                </div> 
                                
                    
                                </Fade>
            </footer>
            <Fade bottom>
            <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}}  target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" style={{backgroundImage: "linear-gradient( white 80%, #BADCF4 20%)"}} target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Blue Heart" style={{ fontSize: "inherit" }}>💙</span>
                    </div>
                    </Fade>
                    </div>
                    
                </Fade>
            </div>
            </div>
        );
    }
};

render(<Parkaid/>, document.getElementById('root'));

export default Parkaid

/*
4. Presentation

It is critical to present designs in conventional mediums, so it is accessible and can be understood with ease. Down below is a poster I created for Parkaid, that both inform and promote the product.

<LazyLoad offsetBottom={600} >
                                        <img style={{ marginTop: "4vh", marginBottom: "4vh"}} src={require('./overview_1.jpg')} width="85%" alt="parkaid poster"></img>
                                    </LazyLoad>

                                    */
/*     <a
                                style={{ fontSize: "inherit", color: "inherit",  backgroundImage: "linear-gradient( white 80%, #787ABD 20%)", marginBottom: "1vh", marginRight: "2vw"}}
                                href="/cocobot"
                                rel="noopener noreferrer">CocoBot</a>  */