import React from 'react'
import '../App.css'
import Fade from 'react-reveal/Fade'
import Nav from './nav.js'
import ProfilePic from './profilepic.JPG'
import divider from './divider_home.png'
import { FiLinkedin, FiMail} from 'react-icons/fi'
import { IconContext } from "react-icons"

/*Design, to me, is the art of creating a meaningful relationship between distinct components to elevate experiences. It should be 
                                    motivated by benevolent intentions and take into consideration the potential impacts. */
                                    /* <h2 style={{ marginTop: "0",  color: "#242424" }}> 2. I speak a dialect spoken by >0.02% of the world</h2>
                                <p style={{ marginBottom: "4vh"}}>My grandmother, who only spoke 沭阳话 ("Shuyanese"), raised me as a baby.
                                I learnt the dialect while living with her and retained it until today. </p> *
                                // /*and allowed me to learn a rare dialect known as 沭阳话 ("Shuyanese") and French.
                                  <div className="col" style={{marginRight:"4vw", paddingBottom: "4vh"}}>
                            <img src={ProfilePic} width="100%" alt="profilepic"></img>
                        </div>*/
/*establishing and leading UX research 
                                at Webby-winning data visualization website called <a className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit"}}
                                href="https://usafacts.org/" 
                                target="_blank" rel="noopener noreferrer">USAFacts</a> as a UX research fellow.*/

function About() {
    return (
        <div>
            <Nav/>
  
        <div className="col-10">
            
                <div className="content-wrap">
                <Fade bottom>
                    <div className="intro-title">
                    Thanks for dropping by!~ <span role="img" aria-label="SmileyFace" style={{ fontSize: "inherit" }}>😊</span><br/><br/> 
                    </div>
                 
                        <div className="row" style={{ marginTop: "6vh"}}>
                        <div className="col" style={{marginRight:"4vw", paddingBottom: "4vh"}}>
                            <img src={ProfilePic} width="100%" alt="profilepic"></img>
                        </div>
                            <div className="col" >
                                <p>
                                My name is Amanda. I'm from Vancouver, B.C., Canada and currently working as a UX Researcher at <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit", color: "#242424"}}
                                href="https://gocoreate.com/" 
                                target="_blank" rel="noopener noreferrer">CoReate</a>.
                                </p><br/><p>I graduated with a double degree in <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit", color: "#242424"}}
                                href="https://www.hcde.washington.edu/bs" 
                                target="_blank" rel="noopener noreferrer">
                                Human Centered Design & Engineering</a>, concentration in Human-Computer Interaction, and 
                                Psychology from the University of Washington in 2020. The two majors perfectly merged my love for creating, helping, and learning about human 
                                interactions and technology. Check out my <a 
                                className="hyperlink-text" style={{ fontSize: "inherit", textDecoration: "underline", textDecorationColor: "inherit", color: "#242424"}}
                                href="https://drive.google.com/file/d/1kaLtTIeeoqHjrfBQRYgMOI_gywXrpOOD/view?usp=sharing" 
                                target="_blank" rel="noopener noreferrer">résumé</a> to learn more about my experience and background.
                                </p> <br/>

                                <p>Inspired by my undergrad degrees 
                                and <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", color: "#242424"}}
                                href="https://www.interaction-design.org/literature/article/dieter-rams-10-timeless-commandments-for-good-design"
                                target="_blank" rel="noopener noreferrer">Dieter Rams' principles of good design</a>, I aspire to use research and a functionalist 
                                perspective to create human-centered and practical products. </p> <br/>

                                <p style={{ fontWeight: "700"}}>Human connections make the world go round, so reach out to me~ Here's my email: <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", color: "#242424"}}
                                href="mailto:amandazhu9810@gmail.com"
                                target="_blank" rel="noopener noreferrer">amandazhu9810@gmail.com</a>.
                                 </p> <br/>
                           
                            </div>
                            
                           
                            </div>
                            <Fade bottom>
                            <img src={divider} className="divider" width="400vw" alt="divider" style={{ marginTop: "6vh"}}></img>

                                <div className="extra-large-text-buddy" 
                                style={{ marginTop: "12vh" }}>
                                    Wanna learn more about me?
                                </div>

                                <div className="extra-large-text" style={{ color: "#FF8800" }}>
                                    Here are Three Random Facts
                                </div>
                               
                                <img src={divider} className="divider" width="400vw" alt="divider" 
                                style={{ marginTop:"12vh", marginBottom: "10vh", transform: "scaleX(-1)"}}></img>
                                 <div className="center-wrap">
                                <h2 style={{  color: "#242424" }}> 1. I'm a third culture kid</h2>
                                                <p style={{ marginBottom: "4vh"}}>I was born in Wuxi, and grew up in Shanghai and Vancouver. I have
                                                lived in numerous places, ranging from remote rural villages to bustling city centers. These experiences
                                                widened my perspective about people, places, and the world.  </p>  
                                <h2 style={{ marginTop: "0",  color: "#242424" }}> 2. I can recognize and name most car brands</h2>
                                <p style={{ marginBottom: "4vh"}}>I can also identify some popular car models. Learning and identifying car brands made waiting for 
                                buses more fun and was my favourite bus-waiting activity throughout high school. </p> 

                                <h2 style={{ marginTop: "0",  color: "#242424" }}> 3. I made it a goal of mine to save up and go to at least one new place every year </h2>
                                <p style={{ marginBottom: "4vh"}}>2019 was my most prolific travel year to date, I visited ~16 places in six different
                                countries. Check out some pictures from my adventures <a 
                                className="hyperlink-text"style={{ textDecoration: "underline", textDecorationColor: "inherit", fontSize: "inherit", color: "#242424"}}
                                href="https://vsco.co/colourtheworld/gallery"
                                target="_blank" rel="noopener noreferrer">here</a>.</p>
                                 
                                </div>
                              
                
                                </Fade>
                                </Fade>
                                <Fade bottom>
                                <IconContext.Provider value={{ color: "242424", size: "1.3rem"}}>
                    <div className="mobile-socials" style={{ marginBottom: "2vh", marginTop: "12vh"}}>
                        <a id="email-icon" href="mailto:amandazhu9810@gmail.com" target="_blank" rel="noopener noreferrer">
                            <FiMail className="gmail"/>
                        </a>
                        <a href="https://www.linkedin.com/in/zhuamanda/" target="_blank" rel="noopener noreferrer">
                            <FiLinkedin className="linkedin"/>
                        </a>
                    </div>
                    </IconContext.Provider>
                        <div className="mobile-credits">
                    © Amanda Zhu 2024 <br />
                    Created with React + <span role="img" aria-label="Orange Heart" style={{ fontSize: "inherit" }}>🧡</span> 
                    </div>
                    </Fade>
                    
                </div>                     
            
        </div>    
        </div>
    )
}

export default About